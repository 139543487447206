@import "editor-resets.scss";
@import "~@ckeditor/ckeditor5-build-decoupled-document/build/frontend.css";
@import "editor-google-docs-way.scss";

/* FORMATAÇÃO DO EDITOR BASEADA NAS DEMOS DO CKEDITOR e CUSTOMIZAÇÕES UNIWARE */
#editor-view {
  @extend .editor-view;
  @extend .ck-content;

  .ck-content {
    padding: 15px;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #ccc;
    min-height: 400px;
  }

  color: #1a1a1a;
  p,
  ul,
  ol,
  blockquote,
  pre {
    font-size: 1em;
    line-height: 1.6em;
    padding-top: 0.2em;
    margin-bottom: 0.8em;
    color: #1a1a1a;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  span {
    color: #1a1a1a;
  }
  h1 {
    font-size: 2.36em;
    line-height: 1.33em;
    padding-top: 1em;
    margin-bottom: 1.67em;
  }
  h1 + dl {
    margin-top: 1em;
  }
  @media only screen and (max-width: 640px) {
    h1 {
      font-size: 1.9em;
    }
  }
  dd {
    margin-bottom: 1em;
  }
  h1:first-of-type {
    width: 100%;
    padding-top: 0.5em;
    margin-bottom: 1.17em;
  }
  h1:first-of-type + h2 {
    padding-top: 0;
  }
  h2 {
    font-size: 1.68em;
    line-height: 1.68em;
    padding-top: 0.8em;
    margin-bottom: 0.4em;
    padding-bottom: 0.2em;
    font-weight: 400;
  }
  @media only screen and (max-width: 640px) {
    h2 {
      font-size: 1.5em;
    }
  }
  h2:first-of-type {
    clear: both;
  }
  h3 {
    font-size: 1.36em;
    line-height: 1.5em;
    padding-top: 0.8em;
    margin-bottom: 0.2em;
    font-weight: 400;
  }
  h4 {
    font-size: 1.2em;
    line-height: 1.4em;
    padding-top: 0.8em;
    margin-bottom: 0.2em;
    margin-bottom: 0.2em;
    padding-top: 0.8em;
    font-weight: 400;
  }
  h5 {
    font-size: 1em;
    line-height: 1.6em;
    padding-top: 0.2em;
    margin-bottom: 0.8em;
    font-weight: 400;
  }
  .info-box > h2,
  .info-box > h3,
  .info-box > h4 {
    padding-top: 0;
  }
  strong,
  b {
    font-weight: 600;
  }
  i,
  em {
    font-style: italic;
  }
  pre {
    overflow: hidden;
  }
  code {
    font-family: "SF Mono", menlo, monaco, "Roboto Mono", Consolas, "Lucida Console", monospace;
    font-size: 0.866666em;
    padding: 1.333em;
  }
  code:not(.hljs) {
    background: rgba(202, 205, 207, 0.3);
    padding: 0.1em 0.25em;
    border-radius: 3px;
  }
  code:not(.hljs):after {
    letter-spacing: -1em;
    content: "\00a0";
  }
  code:not(.hljs):before {
    letter-spacing: -1em;
    content: "\00a0";
  }
  a code:not(.hljs) {
    color: #1b3af2;
  }
  .hljs {
    background: #2b2c26;
  }
  .hljs a {
    color: #fff;
  }
  .hljs code {
    background: none;
    padding: 0;
    font-size: 1em;
  }
  kbd {
    display: inline-block;
    background: #f5f5f5;
    border: solid 1px #b5c6d2;
    border-bottom-color: #97afbf;
    box-shadow: inset 0 -1px 0 #97afbf;
    font-family: "SF Mono", menlo, monaco, "Roboto Mono", Consolas, "Lucida Console", monospace;
    font-size: 0.8em;
    padding: 0.25em 0.5em;
    line-height: 1em;
    vertical-align: middle;
    border-radius: 3px;
  }
  ul,
  ol {
    margin-left: 2.666em;
    margin-bottom: 0.8em;
  }
  @media only screen and (max-width: 640px) {
    ul,
    ol {
      margin-left: 1.333em;
    }
  }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    padding-top: 0;
    margin-bottom: 0;
  }
  ul ul:last-of-type,
  ul ol:last-of-type,
  ol ul:last-of-type,
  ol ol:last-of-type {
    margin-bottom: 0.33333em;
  }
  ul li:last-of-type,
  ol li:last-of-type {
    margin-bottom: 0;
  }
  p img {
    display: block;
    margin: 1.5em auto;
    box-sizing: content-box;
  }
  iframe:not(.cke_wysiwyg_frame) {
    display: block;
    margin: 1.5em auto;
  }
  ol {
    list-style-type: decimal;
  }
  table {
    margin: 1.5em 0;
    width: 100%;
  }
  table code {
    word-break: break-word;
    white-space: normal;
  }
  td,
  th {
    border: 1px solid #e9e9e9;
    padding: 6px 12px;
  }
  th {
    font-weight: bold;
  }
  abbr {
    position: relative;
    cursor: default;
    text-decoration: none;
    border-bottom: 1px dotted #000;
  }
  abbr::before {
    content: attr(title);
    display: none;
    position: absolute;
    bottom: calc(-100% - 15px);
    left: 50%;
    transform: translateX(-50%);
    padding: 3px 5px;
    font-size: 0.9em;
    font-weight: bold;
    border-radius: 3px;
    color: #fff;
    background: black;
    white-space: nowrap;
  }
  abbr::after {
    content: "";
    display: none;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #000 transparent;
  }
  abbr:hover::before,
  abbr:hover::after {
    display: block;
  }

  td {
    p {
      margin: 0;
    }
  }

  .editor-block-indent-a {
    text-indent: 25px;
  }

  .editor-block-indent-b {
    margin-left: 10%;
    text-indent: 25px;
  }

  .editor-block-indent-c {
    margin-left: 20%;
    text-indent: 25px;
  }

  .editor-block-indent-d {
    margin-left: 30%;
    text-indent: 25px;
  }

  .editor-block-indent-e {
    margin-left: 40%;
    text-indent: 25px;
  }

  .image-style-side {
    max-width: 50%;
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }

  .image.image_resized {
    max-width: 100%;
  }

  .image.image_resized {
    display: block;
    box-sizing: border-box;
  }

  .image.image_resized img {
    width: 100%;
  }

  .image.image_resized > figcaption {
    display: block;
  }

  .image-style-align-left {
    float: left;
    margin-right: 1.5em !important;
    margin-right: var(--ck-image-style-spacing) !important;
  }

  .image-style-align-right {
    float: right;
    margin-left: 1.5em !important;
    margin-left: var(--ck-image-style-spacing) !important;
  }

  .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  .image {
    position: relative;
    display: table;
    clear: both;
    text-align: center;
    margin: 1em auto;
  }

  img {
    max-width: 19cm;
  }
}
