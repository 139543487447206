// Cores
$primary: #6cc;
$alert: #c66;

$clr-txt-title: #444;
$clr-txt-content: #777;

$clr-ui-border: #ccc;
$clr-ui-bkg: #f8f8f8;

$clr-social-fb: #3b5998;
$clr-social-gl: #dd4b39;

$global-shadow: 0 0 30px rgba(0, 0, 0, 0.15);

// Fontes
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700');
$Source: 'Source Sans Pro', Helvetica, Arial, sans-serif;

$light: 300;
$regular: 400;
$bold: 700;

$sz-title: 36px;
$sz-content: 18px;
$sz-note: 14px;

#register {
  width: 80%;
  max-width: 420px;
  margin: 180px 10% 100px;

  header {
    overflow: hidden;
    margin-bottom: 60px;

    .logo {
      float: left;
    }

    h1 {
      margin-top: 10px;
      float: right;
      font-size: 26px;
      text-transform: uppercase;
      color: $clr-txt-title;
    }
  }

  .form {
    input {
      margin: 0 0 20px;

      &::placeholder {
        color: $clr-txt-content;
        opacity: 1;
      }
    }

    .g-recaptcha {
      margin-bottom: 20px;
    }

    .sc-login {
      float: left;
      max-width: 50%;
      margin-top: 5px;
      font-size: 16px;

      a {
        display: block;
        color: $primary;
      }
    }

    .btn {
      margin: 0 0 20px;
      float: right;
    }

    .ou {
      clear: both;
      position: relative;
      text-align: center;
      margin-bottom: 20px;

      &:before,
      &:after {
        content: ' ';
        display: block;
        width: 45%;
        height: 1px;
        background-color: $primary;
        position: absolute;
        top: 50%;
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }

    .btn-social {
      display: block;
      width: 100%;
      height: 50px;
      border: 1px solid $clr-ui-border;
      border-radius: 3px;
      background: no-repeat 15px center;
      text-align: center;
      font-size: 16px;
      line-height: 50px;
      margin-bottom: 20px;

      &.fb {
        background-image: url(./assets/img/ico-facebook.png);
        color: $clr-social-fb;
        border-color: $clr-social-fb;
      }

      &.go {
        background-image: url(./assets/img/ico-google.png);
        color: $clr-social-gl;
        border-color: $clr-social-gl;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  #register {
    width: 90%;
    margin: 60px 5%;

    header {
      margin-bottom: 30px;
      text-align: center;

      .logo {
        width: 100%;
      }

      h1 {
        width: 100%;
      }
    }

    .form {
      text-align: center;

      .sc-login {
        max-width: 100%;
        width: 100%;
        margin: 0 0 10px;
      }

      .btn {
        margin: 0 0 20px;
        float: none;
        width: 100%;
      }

      .ou {
        clear: both;
        position: relative;
        text-align: center;
        margin-bottom: 20px;

        &:before,
        &:after {
          content: ' ';
          display: block;
          width: 45%;
          height: 1px;
          background-color: $primary;
          position: absolute;
          top: 50%;
        }

        &:before {
          left: 0;
        }

        &:after {
          right: 0;
        }
      }

      .btn-social {
        display: block;
        width: 100%;
        height: 50px;
        border: 1px solid $clr-ui-border;
        border-radius: 3px;
        background: no-repeat 15px center;
        text-align: center;
        font-size: 16px;
        line-height: 50px;
        margin-bottom: 20px;

        &.fb {
          background-image: url(./assets/img/ico-facebook.png);
          color: $clr-social-fb;
          border-color: $clr-social-fb;
        }

        &.go {
          background-image: url(./assets/img/ico-google.png);
          color: $clr-social-gl;
          border-color: $clr-social-gl;
        }
      }
    }
  }
}
