#editor-view-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;

  #editor-view,
  #print-view {
    width: 21cm;
    padding-left: 1cm;
    padding-right: 1cm;
  }
}

#document-editor {
  // border: 1px solid var(--ck-color-base-border);
  // border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  //max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;

  .document-editor__toolbar {
    /* Make sure the toolbar container is always above the editable. */
    z-index: 1;

    /* Create the illusion of the toolbar floating over the editable. */
    box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);

    /* Use the CKEditor CSS variables to keep the UI consistent. */
    border-bottom: 1px solid var(--ck-color-toolbar-border);
  }

  /* Adjust the look of the toolbar inside the container. */
  .document-editor__toolbar .ck-toolbar,
  .ck.ck-toolbar.ck-rounded-corners {
    border: 0;
    border-radius: 0;
  }

  /* Make the editable container look like the inside of a native word processor application. */
  .document-editor__editable-container {
    padding: calc(2 * var(--ck-spacing-large));
    background: var(--ck-color-base-foreground);

    /* Make it possible to scroll the "page" of the edited content. */
    // overflow-y: scroll;
  }

  .document-editor__editable-container > .ck-editor__editable.ck-editor__editable {
    /* Set the dimensions of the "page". */
    width: 21cm;
    min-height: 21cm;

    /* Keep the "page" off the boundaries of the container. */
    padding: 1cm 1cm 1cm;

    border: 1px hsl(0, 0%, 82.7%) solid;
    border-radius: var(--ck-border-radius);
    background: white;

    /* The "page" should cast a slight shadow (3D illusion). */
    box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

    /* Center the "page". */
    margin: 0 auto;
  }

  .document-editor__editable-container > div {
    padding-left: 1cm;

    padding-right: 1cm;
  }
}

@media screen and (max-width: 820px) {
  #editor-view-wrapper {
    #editor-view,
    #print-view {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
