#container {
  
  .info-modules {
    margin-top: 50px;

    .title {
      margin-bottom: 40px;

      h1 {
        margin-bottom: 0px;
      }
    }
  }
  
  .edit-btn {
    cursor: pointer;
    
    &:hover {
      color: #007bff;
      text-decoration: none;
      background-color: transparent;
    }
  }
}
