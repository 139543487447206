// Biblioteca de mixins
@import 'mixins.scss';

/* Reset */
@import 'reset.scss';
@import 'fonticon.scss';

$primary-icon: #34a4e0;

// Cores
$primary-lighter: #9fdfdf;
$primary: #6cc;
$primary-darker: #39acac;

$btn-danger: #dc3545;
$btn-primary: #007bff;

$alert-lighter: #df9f9f;
$alert: #c66;
$alert-darker: #ac3939;

$success: #0c6;

$mintgreen: #e9f7f2;

$clr-txt-title: #444;
$clr-txt-content: #777;
$clr-txt-note: #999;

$clr-ui-lightborder: #eee;
$clr-ui-border: #ccc;
$clr-ui-bkg: #f8f8f8;
$clr-ui-lightbkg: #fcfcfc;

$clr-social-fb: #3b5998;
$clr-social-gl: #dd4b39;

$clr-support-blue: #06c;
$clr-support-green: #0c6;
$clr-support-yellow: #fc3;

$global-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
$transition: all 200ms ease-in-out;
$opacity: 0.7;
$border-radius: 3px;

// Fontes
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700');
$Source: 'Source Sans Pro', Helvetica, Arial, sans-serif;

$light: 300;
$regular: 400;
$bold: 700;

$sz-title: 36px;
$sz-content: 16px;
$sz-note: 14px;

/* Estilos globais */
body {
  font: $regular $sz-content $Source;
  color: $clr-txt-content;
  @include font-smoothing(on);

  &.w-bkg {
    padding-top: 0;
    background: url(./assets/img/bkg.jpg) no-repeat left top;
  }
}

*,
:focus {
  outline: none;
}

// Hífens
// *{
// 	overflow-wrap: break-word;
// 	word-wrap: break-word;
// 	word-break: break-word;
// 	hyphens: auto;
// }

@include selection(#fff, $primary);

@mixin basic-avatar {
  display: block;
  float: left;
  width: 60px;
  height: 60px;
  margin: 0 10px;
  border-radius: 50%;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
  }
}

.fp-loader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  background-color: rgba(102, 2014, 204, 0.9);

  .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .ico {
      display: none;
      width: 50px;
      height: 50px;
      margin: 0 auto 30px;
      background: url(./assets/img/preloader-black.gif) no-repeat center center;
      background-size: contain;
      opacity: 0.5;
    }

    p {
      color: rgba(0, 0, 0, 0.5);
      text-align: center;
      font-size: 16px;

      strong {
        display: block;
        font-size: 18px;
        font-weight: $bold;
        margin-bottom: 5px;
      }
    }
  }
}

div.alert {
  width: 100%;
  padding: 15px;
  border-left: 5px solid $clr-ui-border;
  background-color: $clr-ui-bkg;
  margin-bottom: 20px;
  position: relative;
  clear: both;

  .close {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $alert;

    position: absolute;
    top: -10px;
    right: -10px;

    &:before {
      font-family: 'icomoon';
      font-size: 9px;
      content: '\e919';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }

  &.error {
    border-left-color: $alert;

    span,
    a {
      color: $alert;
    }

    strong {
      font-weight: $bold;
    }
  }

  &.success {
    border-left-color: $success;

    .close {
      background-color: $success;
    }

    span,
    a {
      color: $success;
    }

    strong {
      font-weight: $bold;
    }
  }
}

.container-config {
  display: block;
  width: 500px;
}

.abbreviate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.centered-text {
  text-align: center;
}

// Notificação
.notice {
  border-left: 5px solid $primary;
  background-color: $clr-ui-bkg;
  padding: 20px;

  span {
    color: $primary;
  }
}

label.term {
  a {
    color: $primary;

    &:hover {
      text-decoration: underline;
    }
  }
}

#container {
  width: 90%;
  margin: 120px auto 60px;
  padding: 40px 0 80px;

  .centered,
  &.centered {
    max-width: 1024px;
    margin: 0 auto;
  }

  // Tipografia
  h1 {
    font-size: $sz-title;
    color: $clr-txt-title;
    margin-bottom: 60px;
  }

  > h2 {
    font-size: 22px;
    color: $clr-txt-title;
    margin-bottom: 30px;

    &.list-title {
      font-size: 24px;

      i {
        color: $primary;
      }
    }
  }

  > p {
    line-height: 1.5;
    margin-bottom: 20px;

    strong {
      font-weight: $bold;
    }

    em {
      font-style: italic;
    }

    a {
      color: $primary;
      text-decoration: underline;
    }
  }

  .centered-title {
    text-align: center;
  }

  .centered-text {
    text-align: center;
    margin-bottom: 80px;

    h1 {
      margin-bottom: 30px;
    }

    p {
      max-width: 580px;
      margin: 0 auto;

      strong {
        font-weight: $bold;
      }
    }
  }

  pre {
    white-space: pre-wrap;
  }

  /* Títulos com botões */
  .title-button {
    overflow: hidden;
    margin-bottom: 60px;

    h1 {
      float: left;
      max-width: 50%;
      margin: 0;
    }

    .button-stack {
      float: right;
      max-width: 50%;
      clear: none;

      .btn {
        height: 40px;
        margin-bottom: 0;
      }
    }
  }
}

// ******************************************
// Painel de controle
// ******************************************

#dashboard-wrapper {
  column-count: 2;
  column-gap: 5em;

  .dashboard-block {
    display: inline-block;
    width: 100%;
    margin: 0 0 5em 0;
    border: 1px solid $clr-ui-lightborder;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius;

    &:nth-child(odd) {
      margin-right: 0;
    }

    &.full-width {
      width: 100%;
      margin: 0;
    }

    h2 {
      font-size: 22px;
      font-weight: $bold;
      color: $clr-txt-title;
      padding: 20px;
      background-color: $clr-ui-bkg;
    }

    .status-list {
      margin: 40px 20px;
      overflow: hidden;

      li {
        float: left;
        width: 33.33%;
        text-align: center;
      }
      .status {
        p {
          font-size: 20px;

          strong {
            display: block;
            font-size: 40px;
            font-weight: $bold;
            color: $primary;
          }
        }
      }
    }

    .dashboard-list {
      margin: 20px;
      overflow: hidden;

      li {
        padding: 20px 0;
        border-bottom: 1px solid $clr-ui-border;
        cursor: pointer;
        transition: $transition;

        .tooltip {
          position: absolute;
          top: 10px;
          right: 10px;
          opacity: 0;
          transition: $transition;
          background-color: $alert;
          color: #fff;
          padding: 5px 10px;
          font-size: $sz-note;
        }

        &.not-clickable {
          cursor: default;

          &:hover {
            h3,
            p {
              color: inherit;
            }
          }

          .lower-details {
            margin-bottom: 10px;
          }
        }

        &:hover {
          h3,
          p {
            color: $primary;
          }

          .tooltip {
            opacity: 1;
          }
        }

        &:last-child {
          border: 0;
        }

        > h3 {
          max-width: 60%;
          font-size: 19px;
          line-height: 1.4;
          margin-bottom: 10px;
          color: $clr-txt-title;
          transition: $transition;
        }

        p {
          transition: $transition;
        }

        .upper-details {
          position: absolute;
          top: 30px;
          right: 20px;
          min-width: 30%;

          p {
            float: left;
            line-height: 24px;
            margin-left: 30px;
            background: no-repeat center left;

            &:first-child {
              margin-left: 0;
            }

            i {
              display: block;
              float: left;
              color: $primary;
              font-size: 24px;
              margin-right: 5px;
            }

            strong {
              font-weight: $bold;
            }
          }

          a {
            display: block;
            float: right;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-left: 10px;
            transition: $transition;
            position: relative;

            &:hover {
              opacity: $opacity;
            }

            &.edit {
              background-color: $primary;

              &:before {
                font-family: 'icomoon';
                font-size: 19px;
                content: '\e90a';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
              }
            }
          }

          .avatar-wrapper {
            float: right;

            .avatar {
              float: left;
              width: 40px;
              height: 40px;
              margin-right: 10px;
              border-radius: 50%;
              overflow: hidden;

              img {
                display: block;
                width: 100%;
              }
            }

            .name {
              display: block;
              float: left;
              margin-top: 10px;
            }
          }
        }

        .lower-details {
          // position:absolute;
          width: 100%;
          bottom: 30px;
          right: 20px;

          font-size: $sz-note;

          em {
            color: $primary;
          }

          strong {
            font-weight: $bold;
          }
        }

        .bar {
          width: 100%;
          height: 50px;
          background-color: $clr-ui-bkg;

          .used {
            position: relative;
            // width: 30%;
            height: 50px;
            background-color: $primary;

            span {
              display: inline-block;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: #fff;
              font-weight: $bold;
            }
            &.morethanninety {
              background-color: $alert;
            }
          }
        }

        .user {
          overflow: hidden;

          .avatar {
            float: left;
            width: 40px;
            height: 40px;
            margin: 10px 10px 0 0;
            border-radius: 50%;
            overflow: hidden;

            img {
              display: block;
              width: 100%;
            }
          }

          .info {
            float: left;
            margin: 5px 0;

            h3 {
              font-size: 19px;
              line-height: 1.4;
              color: $clr-txt-title;
            }

            p {
              font-size: $sz-note;
              color: $primary;
            }
          }
        }

        .new-owner {
          display: none;
          width: 100%;
          overflow: hidden;
          padding: 10px;
          border: 1px solid $clr-ui-lightborder;

          label {
            width: 60%;
            margin: 0 2% 0 0;
          }

          button {
            float: left;
            width: 38%;
            margin: 26px 0 0;
            height: 40px;
            line-height: 1;
          }
        }
      }
    }

    .capacity-wrapper {
      margin: 20px;

      .capacity-bar {
        width: 100%;
        height: 120px;
        margin: 30px 0;
        background-color: $clr-ui-lightbkg;

        .bar {
          float: left;
          height: 120px;
          position: relative;

          span {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            font-size: 26px;
            font-weight: $bold;
          }

          &.unavailable {
            background-color: $primary;
            color: #fff;
          }

          &.available {
            background-color: $clr-ui-lightborder;
          }
          &.almost-full {
            background-color: $alert;
          }
        }
      }

      .caption {
        overflow: hidden;
        margin-bottom: 30px;

        p {
          float: left;
          width: 33.33%;
          text-align: center;

          span {
            display: inline-block;
            height: 20px;
            vertical-align: middle;
          }

          .block {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }

          &.unavailable {
            .block {
              background-color: $primary;
            }
          }

          &.available {
            .block {
              background-color: $clr-ui-lightborder;
            }
          }
          &.almost-full {
            .block {
              background-color: $alert;
            }
          }
        }
      }
    }

    .lower-link {
      a {
        display: block;
        width: 100%;
        text-align: center;
        padding: 15px 0 20px;
        color: $primary;
        transition: $transition;

        i {
          display: inline-block;
          font-size: 21px;
          margin-right: 5px;
          transform: translatey(5px);
        }

        &:hover {
          background-color: $primary;
          color: #fff;
        }
      }
    }
  }
}

// ******************************************
// Formulários
// ******************************************
.form {
  display: inline-block;
  width: 100%;

  .note {
    width: 98%;
    margin: 0 1% 5px;
    font-size: $sz-note;
    color: $clr-txt-note;

    span {
      color: $primary;
    }
  }

  div.row,
  label {
    display: block;
    float: left;
    width: 98%;
    margin: 0 1% 20px;
    color: $clr-txt-title;
    text-align: left;

    &.no-spacing {
      margin-bottom: 5px;
    }

    .required {
      color: $alert;
    }

    &.split2 {
      width: 48%;
    }

    &.split3 {
      width: 31.33%;

      &.colspan {
        width: 64.66%;
      }
    }

    &.split4 {
      width: 23%;

      &.colspan {
        width: 73%;
      }
    }

    &.split6 {
      width: 14.66%;
    }

    &.tree-view-label {
      margin-bottom: 5px;
    }

    &.search {
      position: relative;

      button {
        position: absolute;
        bottom: 0;
        right: 0;
        display: block;
        width: 40px;
        height: 40px;
        background-color: $primary;
        border: 0;
        border-radius: 0 3px 3px 0;
        cursor: pointer;
        transition: $transition;
        color: $primary;
        overflow: hidden;

        &:before {
          font-family: 'icomoon';
          font-size: 19px;
          content: '\e918';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
        }

        &:hover {
          opacity: $opacity;
        }
      }
    }
  }

  .percentage {
    position: relative;

    .btn-hover {
      display: block;
      width: 40px;
      height: 40px;
      border: 1px solid $clr-ui-border;
      border-radius: 0 3px 3px 0;
      background-color: $clr-ui-bkg;
      cursor: pointer;

      position: absolute;
      bottom: 0;
      right: 0;

      &:before {
        font-family: 'icomoon';
        font-size: 20px;
        content: '%';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $clr-txt-note;
      }

      &.alt {
        &:before {
          content: '%';
        }
      }
      &:focus {
        border-color: $primary;
      }
    }
  }

  .password {
    position: relative;

    .btn-hover {
      display: block;
      width: 40px;
      height: 40px;
      border: 1px solid $clr-ui-border;
      border-radius: 0 3px 3px 0;
      background-color: $clr-ui-bkg;
      cursor: pointer;

      position: absolute;
      bottom: 0;
      right: 0;

      &:before {
        font-family: 'icomoon';
        font-size: 20px;
        content: '\e90b';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $clr-txt-note;
      }

      &.alt {
        &:before {
          content: '\e90c';
        }
      }
      &:focus {
        border-color: $primary;
      }
    }
  }

  .search {
    position: relative;

    .btn-hover {
      display: block;
      width: 40px;
      height: 40px;
      border: 1px solid $clr-ui-border;
      border-radius: 0 3px 3px 0;
      background-color: $clr-ui-bkg;
      cursor: pointer;
      position: absolute;
      bottom: 0;
      right: 0;

      &:before {
        font-family: 'icomoon';
        font-size: 19px;
        content: '\e918';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $clr-txt-note;
      }

      &:focus {
        border-color: $primary;
      }
    }
  }

  input[type='text'],
  input[type='date'],
  input[type='file'],
  input[type='email'],
  input[type='number'],
  input[type='password'],
  select,
  textarea {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0 15px;
    border: 1px solid $clr-ui-border;
    border-radius: $border-radius;
    margin-top: 5px;
    background-color: #fff;
    font-size: $sz-content;
    font-family: $Source;
    color: $clr-txt-content;
    background-color: #fff;
    transition: $transition;

    &.hidden {
      display: none;
    }

    &:focus {
      border-color: $primary;
      outline: none;
    }

    option {
      color: $clr-txt-content;
      padding: 3px 0;
    }
  }

  input[type='text'],
  input[type='file'],
  input[type='email'],
  input[type='number'],
  textarea {
    &:read-only {
      background-color: $clr-ui-lightbkg;
      border-color: $clr-ui-lightborder;
      color: $clr-txt-content;
      pointer-events: none;
    }
  }

  select.multiple,
  textarea {
    padding: 15px;
    height: 120px;
    resize: vertical;
  }

  select.multiple {
    padding: 0;

    option {
      padding: 5px 15px;
    }
  }

  select[multiple]:focus option:checked {
    background: $primary linear-gradient(0deg, $primary 0%, $primary 100%);
  }

  // Fieldsets e legends
  fieldset {
    width: 100%;
    padding: 20px 20px 10px;
    margin-bottom: 20px;
    border: 1px solid $clr-ui-border;

    legend {
      font-weight: $bold;
      color: $primary;
      padding: 0 10px;
    }
  }
}

#wrapper-print,
#wrapper-download {
  display: none;
}

// Estilo do toggle (substitui o checkbox)

.custom-check {
  input[type='checkbox'] {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
  }

  input[type='checkbox']:checked {
    + label {
      &:after {
        left: 11px;
      }

      &:before {
        background-color: $primary;
      }
    }
  }
}

.alt-checkbox {
  position: relative;
  transition: $transition;

  input[type='checkbox'] {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
  }

  &.focused {
    color: $primary;
  }
}
.togglecheck {
  display: inline-block;
  width: 30px;
  height: 20px;
  border-radius: 10px;
  background-color: $clr-ui-lightborder;
  margin-right: 5px;
  position: relative;
  z-index: 2;
  transition: all 300ms ease-in-out;
  transform: translatey(5px);
  cursor: pointer;

  &.selected {
    background-color: $primary;
  }

  &:after {
    display: block;
    content: ' ';
    width: 20px;
    height: 20px;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: -1px;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transition: $transition;
  }

  &.selected:after {
    left: 11px;
  }
}

.button-stack {
  width: 100%;
  text-align: right;
  clear: both;
}

.avatar-selection {
  width: 100%;
  overflow: hidden;

  &.with-avatar {
    margin-bottom: 10px;

    .button-stack {
      margin-top: 30px;

      p {
        margin-bottom: 5px;
        font-size: $sz-note;
        color: $clr-txt-note;
      }
    }
  }

  .avatar-placeholder {
    float: left;
    width: 150px;
    height: 150px;
    margin-right: 30px;
    border: 1px solid $clr-ui-border;
    border-radius: 50%;
    background: #fff url(./assets/img/ico-camera.png) no-repeat center center;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
    }
  }

  .button-stack {
    float: left;
    width: 60%;
    text-align: left;

    &.avatar-button-stack {
      clear: none;
    }
  }
}

.btn {
  display: inline-block;
  min-width: 180px;
  height: 50px;
  padding: 0 30px;
  border: 1px solid transparent;
  border-radius: $border-radius;
  background-color: $primary;
  color: white;
  font: $bold $sz-content $Source;
  text-transform: uppercase;
  cursor: pointer;
  transition: $transition;
  margin: 0 1% 20px;

  // Fix para usar âncora ao invés de botão
  line-height: 47px;
  text-align: center;

  &:hover {
    opacity: $opacity;
  }

  &:focus {
    border: 1px solid $primary-darker;
  }

  &.alert {
    background-color: $alert;
  }

  &.disabled {
    background-color: $clr-ui-border;
    cursor: default;
    pointer-events: none;
    color: $clr-txt-content;
    font-weight: $regular;
    opacity: 0.3;
  }

  &.alternate {
    background-color: transparent;
    border: 1px solid $primary;
    color: $primary;
    font-weight: $regular;

    &:hover {
      opacity: 1;
      background-color: $primary;
      color: white;
    }

    &.alert {
      border-color: $alert;
      color: $alert;

      &:hover {
        background-color: $alert;
        color: white;
      }
    }

    &.disabled {
      border-color: $clr-ui-border;
      color: $clr-ui-border;
      opacity: 1;
    }
  }

  &.small {
    height: 30px;
    padding: 0 10px;
    line-height: 27px;
    text-transform: none;
  }

  &.btn-primary {
    background-color: $btn-primary;
  }

  &.btn-danger {
    background-color: $btn-danger;
  }
}

/* Cabeçalho */
#header {
  width: 100%;
  height: 80px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: $global-shadow;

  #menu-button {
    display: block;
    float: left;
    position: relative;
    background-color: $primary;
    width: 80px;
    cursor: pointer;
    height: 80px;

    &:before,
    &:after,
    span {
      content: ' ';
      display: block;
      position: absolute;
      left: 20px;
      width: 40px;
      height: 3px;
      background-color: #fff;

      transition: $transition;
    }

    &:before {
      top: 26px;
    }
    &:after {
      bottom: 25px;
    }
    span {
      top: 39px;
    }

    &.active:before {
      transform: rotate(45deg);
      top: 39px;
    }

    &.active:after {
      transform: rotate(-45deg);
      bottom: 38px;
    }

    &.active span {
      transform: rotate(45deg);
      opacity: 0;
    }
  }

  #logo {
    display: block;
    float: left;
    margin: 15px 0 0 30px;

    img {
      display: block;
    }
  }

  #main-search {
    float: right;
    width: 300px;
    margin: 10px 40px 0 0;

    label {
      input {
        height: 50px;
      }

      &.busca {
        button {
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  #notifications-tip {
    display: block;
    float: right;
    position: relative;
    width: 30px;
    height: 30px;
    margin: 25px 40px 0 0;
    background: url(./assets/img/ico-sino.png) no-repeat center center;

    span {
      display: block;
      position: absolute;
      top: -5px;
      right: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $alert;
    }
  }

  #user-tip {
    display: block;
    float: right;
    width: 50px;
    height: 50px;
    margin: 15px 30px 0 0;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
    }
  }
}

/* Main menu */
#menu-closer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 997;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

#main-menu {
  position: fixed;
  z-index: 998;
  top: 0;
  left: -340px;
  width: 320px;
  height: 100%;
  padding-top: 140px;
  background-color: #fff;
  box-shadow: $global-shadow;
  transition: $transition;

  &.opened {
    left: 0;
  }

  ul {
    li.top-level-item {
      margin-bottom: 30px;
      position: relative;

      &.has-childs > a:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 8px;
        right: 10%;
        width: 15px;
        height: 10px;
        background: url(./assets/img/tip-down.png) no-repeat center center;
        transition: $transition;
      }

      &.has-childs > a.active:after {
        transform: rotate(180deg);
      }

      & > a {
        display: block;
        border-left: 10px solid transparent;
        padding-left: 20px;
        font-size: 20px;
        font-weight: $bold;
        color: $clr-txt-title;
        transition: $transition;

        &.active {
          border-left-color: $primary;
        }
      }

      ul {
        display: none;
        padding: 15px 0 0 60px;

        li {
          margin-bottom: 20px;

          &:last-child {
            margin: 0;
          }

          a {
            transition: $transition;

            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }
}

/* User menu */
#user-menu {
  position: fixed;
  z-index: 997;
  top: 80px;
  right: 0;
  width: 340px;
  background-color: #fff;
  box-shadow: $global-shadow;

  #user-card {
    background-color: $clr-ui-bkg;
    padding: 30px;
    overflow: hidden;

    #user-avatar {
      float: left;
      width: 80px;
      height: 80px;
      margin-right: 20px;
      overflow: hidden;
      border-radius: 50%;

      img {
        display: block;
        width: 100%;
      }
    }

    #user-info {
      padding-top: 28px;

      h2 {
        width: 180px;
        color: $clr-txt-title;
        font-weight: $bold;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .job-title {
        font-size: $sz-note;
        color: $primary;
        margin-bottom: 20px;
      }

      .module {
        font-size: $sz-note;

        strong {
          font-weight: $bold;
        }
      }
    }
  }

  ul {
    padding: 20px 0;

    li {
      a {
        display: block;
        padding: 10px 30px;
        transition: $transition;

        &:hover {
          background-color: $clr-ui-bkg;
        }
      }

      &:last-child a {
        color: $alert;
      }
    }
  }
}

/* Smart button */
#smart-button {
  position: fixed;
  bottom: 100px;
  right: 40px;
  z-index: 998;

  .main-button {
    display: block;
    width: 60px;
    height: 60px;
    background-color: $primary;
    border-radius: 50%;
    position: relative;
    transform: rotate(45deg);
    transition: $transition;
    box-shadow: $global-shadow;

    &:before,
    &:after {
      content: ' ';
      display: block;
      width: 20px;
      height: 3px;
      background-color: #fff;
      position: absolute;
      top: 29px;
      left: 20px;
      transform: rotate(-45deg);
    }

    &:before {
      top: auto;
      bottom: 28px;
      transform: rotate(45deg);
    }
  }

  &.active .main-button {
    transform: rotate(0);
  }

  ul {
    display: none;
    position: absolute;
    bottom: 65px;
    right: -5px;
    padding: 10px 10px 0;
    border-radius: $border-radius;

    li {
      text-align: right;
      min-width: 300px;
      margin-bottom: 20px;

      a {
        display: block;
        overflow: hidden;
        line-height: 50px;
        transition: $transition;

        &:hover {
          opacity: $opacity;
        }

        .icon {
          display: block;
          float: right;
          width: 50px;
          height: 50px;
          margin-left: 20px;
          background-color: $clr-ui-lightborder;
          border-radius: 50%;
          font-size: 21px;
          position: relative;

          i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}

/* Documentos */
#inactive-header {
  width: 100%;
  margin-bottom: 40px;
  overflow: hidden;

  .info {
    float: left;
    max-width: 70%;

    h2 {
      @include font-size(22px);
      color: $clr-txt-title;
      margin-bottom: 5px;
    }
  }

  .role-avatar {
    float: right;
    max-width: 30%;

    strong {
      font-weight: $bold;
    }

    .name {
      display: block;
      float: left;
      margin-top: 15px;
      max-width: 170px;
      font-size: $sz-note;

      strong {
        display: block;
        font-size: $sz-content;
      }
    }

    .avatar {
      @include basic-avatar;
    }
  }
}

.breadcrumbs-wrapper {
  margin-bottom: 40px;

  .inner-bread {
    display: inline-block;
  }

  span {
    display: inline-block;
    margin: 0 5px;
    color: $clr-txt-content;
    font-weight: $bold;
  }

  strong {
    display: inline-block;
    font-weight: $bold;
    color: $primary;
  }
}

.breadcrumbs {
  color: $primary;
  @include font-size(18px);

  span {
    display: inline-block;
    margin: 0 5px;
    color: $clr-txt-content;
    font-weight: $bold;
  }

  a:hover {
    text-decoration: underline;
  }

  strong {
    font-weight: $bold;
  }
}

.attach-button {
  clear: both;
  display: block;
  width: 98%;
  padding: 15px;
  border: 1px solid $clr-ui-border;
  margin: 0 auto 20px;
  border-radius: $border-radius;
  transition: $transition;
  line-height: 40px;

  i {
    float: left;
    color: $primary;
    font-size: 40px;
    margin-right: 10px;
  }

  &:hover {
    background-color: $clr-ui-bkg;
  }
}

#file-upload-return {
  display: none;
  width: 98%;
  margin: 0 1% 20px;
  padding: 20px;
  border: 1px solid $clr-ui-lightborder;
  border-radius: $border-radius;

  h2 {
    font-size: 22px;
    color: $clr-txt-title;
    margin-bottom: 30px;
  }

  ol {
    list-style: decimal;
    margin: 0 0 30px 20px;

    li {
      margin-bottom: 10px;
    }
  }

  p {
    strong {
      font-weight: $bold;
    }
  }
}

.title-menu {
  width: 100%;
  position: relative;

  h1 {
    max-width: 80%;
  }
}

.right-menu {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 180px;
  z-index: 1;

  .btn-menu {
    display: block;
    position: absolute;
    top: 5px;
    right: 0;
    padding: 10px 5px;

    span,
    &:before,
    &:after {
      display: block;
      float: left;
      content: ' ';
      width: 6px;
      height: 6px;
      background-color: $clr-ui-border;
      border-radius: 50%;
      margin: 0 3px;
      transition: $transition;
    }

    &.active span,
    &.active:before,
    &.active:after,
    &:hover span,
    &:hover:before,
    &:hover:after {
      background-color: $primary;
    }
  }

  .submenu {
    display: none;
    min-width: 180px;
    padding: 10px 20px 5px;
    border: 1px solid $clr-ui-lightborder;
    margin-top: 40px;
    background-color: #fff;

    li {
      a {
        display: block;
        padding: 5px 0;
        margin-bottom: 2px;
        transition: $transition;

        &:hover {
          opacity: $opacity;
        }

        &.alt {
          color: $alert;

          i {
            color: $alert;
          }
        }
      }
    }
  }
}

#document,
.content-viewer {
  margin-bottom: 40px;

  p {
    line-height: 1.5;
    margin-bottom: 15px;

    em {
      font-style: italic;
    }

    a {
      color: $primary;

      &:hover {
        text-decoration: underline;
      }
    }

    b,
    strong {
      font-weight: $bold;
    }
  }

  blockquote {
    margin: 30px 0 30px 40px;
    border-left: 5px solid $primary;
    padding-left: 10px;
    max-width: 600px;
  }

  table {
    border-collapse: collapse;
    width: 100% !important;
    max-width: 700px;

    th {
      background-color: $clr-ui-lightbkg;
      font-weight: bold;
    }

    th,
    td {
      border: 1px solid $clr-ui-border;
      padding: 10px;
    }
  }

  ul {
    list-style: disc;
    margin-left: 30px;
  }
  ol {
    list-style: decimal;
    margin-left: 30px;
  }
  li {
    margin: 5px 0;
  }

  h1 {
    margin: 30px 0 20px !important;
    font-size: 28px;
    color: $clr-txt-title;
  }

  h2 {
    margin: 30px 0 20px;
    font-size: 22px;
    font-weight: $bold;
    color: $clr-txt-title;
  }

  h3 {
    margin: 30px 0 20px;
    font-size: 18px;
    font-weight: $bold;
    color: $clr-txt-title;
  }

  h4 {
    margin: 30px 0 20px;
    font-size: 18px;
    color: $clr-txt-title;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.heading-comparison {
  overflow: hidden;
  margin-bottom: 40px;
  border: 1px solid $clr-ui-lightborder;
  border-radius: 3px;
  padding: 10px;

  p {
    float: left;
    margin-top: 15px;
  }

  .btn {
    float: right;
    margin: 0;
  }
}

#version-comparison {
  .version {
    float: left;
    width: 48%;
    margin-bottom: 50px;

    &:first-child {
      margin-right: 4%;
    }

    h2 {
      font-size: 21px;
      color: $clr-txt-title;
      margin-bottom: 10px;
    }

    .data {
      line-height: 21px;

      strong {
        font-weight: $bold;
      }

      i {
        float: left;
        color: $primary;
        font-size: 21px;
        margin-right: 10px;
      }
    }
  }

  .subs {
    clear: both;
    margin-bottom: 50px;

    h2 {
      font-size: 21px;
      color: $clr-txt-title;
      margin-bottom: 10px;
    }

    span {
      display: inline-block;
      margin-right: 20px;
      padding: 5px 10px;

      &.del {
        background-color: #f5e0e0;
        color: $alert-darker;
      }

      &.ins {
        background-color: #e0f5f5;
        color: $primary-darker;
      }
    }
  }

  .text-compared {
    div {
      margin-bottom: 10px;
      padding: 5px 10px;

      p {
        line-height: 1.5;
      }

      em {
        font-style: italic;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }

      b,
      strong {
        font-weight: $bold;
      }

      blockquote {
        border-left: 5px solid $primary;
        padding-left: 10px;
        max-width: 600px;
      }

      ul {
        list-style: disc;
        margin-left: 30px;
      }
      ol {
        list-style: decimal;
        margin-left: 30px;
      }
      li {
        margin: 5px 0;
      }

      h1 {
        font-size: 28px;
      }

      h2 {
        font-size: 22px;
      }

      h3 {
        font-size: 18px;
        font-weight: $bold;
      }

      h4 {
        font-size: 18px;
      }

      img {
        max-width: 100%;
        height: auto;
      }

      &:empty {
        display: none;
      }

      &.del {
        padding-top: 20px;
        background-color: #f5e0e0;
        color: $alert-darker;
        max-height: 20px;
        overflow: hidden;
        transition: $transition;
        position: relative;
        cursor: pointer;

        &:after {
          font-family: 'icomoon';
          content: '\e913';
          font-size: 8px;
          display: block;
          position: absolute;
          left: 50%;
          top: 7px;
          transform: rotate(90deg);
          transition: $transition;
        }

        &.expanded {
          max-height: 500px;

          &:after {
            transform: rotate(-90deg);
          }
        }
      }

      &.ins {
        background-color: #e0f5f5;
        color: $primary-darker;
      }
    }
  }
}

.reading-confirmation,
.attachments-wrapper {
  h2 {
    font-size: 21px;
    color: $clr-txt-title;
    margin-bottom: 30px;
  }
}
.attachments {
  margin: 0 0 20px;
  overflow: hidden;

  li {
    float: left;
    width: 49%;
    margin: 0 2% 2% 0;
    border: 1px solid $clr-ui-lightborder;
    position: relative;

    &:nth-child(2n) {
      margin-right: 0;
    }

    a {
      display: block;
      padding: 15px;
      transition: $transition;
      line-height: 21px;

      i {
        float: left;
        color: $primary;
        font-size: 21px;
        margin-right: 10px;
      }

      &:hover {
        color: $clr-txt-title;
        background-color: $clr-ui-bkg;
      }
    }

    .delete {
      opacity: 0;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 30px;
      height: 100%;
      background-color: $alert;
      color: #fff;
      transition: $transition;

      &:hover {
        background-color: $alert;
      }

      span {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &:hover .delete {
      opacity: 1;
    }
  }
}

.message-wrapper {
  display: none;
}

#document {
  #active-header {
    overflow: hidden;
    margin-bottom: 30px;
    position: relative;

    .upper {
      overflow: hidden;
      border-bottom: 1px solid $clr-ui-border;
      padding-bottom: 15px;
      margin-bottom: 30px;

      .customer-name {
        font-size: 21px;
        font-weight: $bold;
        color: $primary;
      }

      .code {
        color: $clr-txt-title;
        font-weight: $bold;
      }

      & > .breadcrumbs {
        border-bottom: 1px solid $clr-ui-border;
        margin-bottom: 30px;
        font-size: $sz-content;

        p {
          display: inline-block;
          color: $clr-txt-content;
        }

        strong {
          color: $clr-txt-title;
          font-weight: $bold;
          display: inline-block;
        }
      }

      .roles {
        .role-avatar {
          float: left;
          text-align: center;
          width: 33.33%;

          .header {
            display: block;
            width: 100%;
            margin-bottom: 20px;
            font-size: 21px;
            color: $clr-txt-title;
          }

          .avatar {
            display: inline-block;
            width: 80px;
            height: 80px;
            margin-bottom: 0;
            border-radius: 50%;
            overflow: hidden;

            img {
              display: block;
              width: 100%;
            }
          }

          .name {
            display: block;
            font-weight: $bold;
            margin: 0;
          }
        }
      }
    }

    .dates {
      width: 100%;
      padding-bottom: 30px;
      border-bottom: 1px solid $clr-ui-border;
      margin-bottom: 30px;
      overflow: hidden;

      .date {
        display: block;
        float: left;
        width: 50%;
        line-height: 21px;

        i {
          float: left;
          font-size: 21px;
          color: $primary;
          margin-right: 10px;
        }

        strong {
          font-weight: $bold;
        }
      }
    }

    .lower {
      padding-bottom: 30px;
      border-bottom: 1px solid $clr-ui-border;
      overflow: hidden;

      h2 {
        @include font-size(18px);
        font-weight: $bold;
        margin: 0 0 5px;
      }

      .left {
        float: left;
        width: 80%;

        .version {
          color: $clr-txt-title;
          font-size: 18px;
        }
      }

      .right {
        float: left;
        width: 20%;

        p {
          strong {
            display: block;
            font-weight: $bold;
          }
        }

        .versions {
          display: block;
          max-width: 120px;
          height: 36px;
          padding-left: 40px;
          margin-bottom: 20px;
          background: url(./assets/img/ico-versoes.png) no-repeat center left;
          transition: $transition;

          &:hover {
            opacity: $opacity;
          }
        }

        .btn.small {
          min-width: 0;
          width: 100%;
          margin: 0 0 10px;
        }
      }
    }

    .btn-menu {
      display: block;
      position: absolute;
      top: 43px;
      right: 0;
      padding: 10px 5px;

      span,
      &:before,
      &:after {
        display: block;
        float: left;
        content: ' ';
        width: 6px;
        height: 6px;
        background-color: $clr-ui-border;
        border-radius: 50%;
        margin: 0 3px;
        transition: $transition;
      }

      &.active span,
      &.active:before,
      &.active:after,
      &:hover span,
      &:hover:before,
      &:hover:after {
        background-color: $primary;
      }
    }

    .tools {
      display: none;
      max-width: 260px;
      margin: 0;
      padding: 20px 20px 0;
      list-style: none;

      position: absolute;
      top: 30px;
      right: 50px;

      border-radius: $border-radius;
      background-color: #fff;
      border: 1px solid $clr-ui-lightborder;
      box-shadow: $global-shadow;

      li {
        margin: 0 0 20px;

        a {
          transition: $transition;

          i {
            display: inline-block;
            margin-right: 10px;
            font-size: 19px;
          }

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}

// ***** Versão de impressão
#print-view {
  max-width: 21cm;
  width: 100%;
  margin: 0 auto;
  font-size: 11pt;
  line-height: 1.5;
  color: #000;

  .floating-print-button {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: $primary;
    color: #fff;
    text-align: center;
    font-size: 27px;
    line-height: 70px;
    overflow: hidden;
  }

  strong {
    font-weight: $bold;
  }

  header {
    margin-top: 3cm;
    overflow: hidden;

    page-break-after: always;
  }

  .info-company {
    border: 1pt solid #888;
    padding: 0.5cm;
    margin-bottom: 0.3cm;
    overflow: hidden;

    p {
      text-align: center;
      font-size: 16pt;
      font-weight: $bold;
    }
  }

  .info-document {
    border: 1pt solid #888;
    padding: 0.5cm;
    margin-bottom: 0.3cm;
    overflow: hidden;

    h1 {
      font-size: 20pt;
      margin: 0 0 0.3cm;
      font-weight: $bold;
    }

    .code {
      float: left;
      width: 20%;

      strong {
        display: block;
      }
    }

    .section {
      float: left;
      width: 80%;

      p {
        font-size: 11pt;
        line-height: 1.5;
        color: #000;
        font-weight: $bold;
      }
    }
  }

  .functions {
    border: 1pt solid #888;
    padding: 0.5cm;
    margin-bottom: 0.3cm;
    overflow: hidden;

    p {
      float: left;
      width: 33%;

      strong {
        display: block;
      }
    }
  }

  .dates {
    border: 1pt solid #888;
    padding: 0.5cm;
    margin-bottom: 0.3cm;
    overflow: hidden;

    p {
      float: left;
      width: 50%;

      strong {
        display: block;
      }
    }
  }

  .version {
    border: 1pt solid #888;
    padding: 0.5cm;
    margin-bottom: 0.3cm;
    overflow: hidden;

    p {
      float: left;

      &.numvers {
        width: 20%;
      }

      &.descvers {
        width: 80%;

        strong {
          display: block;
        }
      }
    }
  }

  .content-doc {
    p {
      font-size: 11pt;
      line-height: 1.5;
      margin-bottom: 15px;
      color: #000;

      em {
        font-style: italic;
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }

      b,
      strong {
        font-weight: $bold;
      }
    }

    blockquote {
      margin: 30px 0 30px 40px;
      border-left: 5px solid $primary;
      padding-left: 10px;
      max-width: 600px;
    }

    ul {
      list-style: disc;
      margin-left: 30px;
    }
    ol {
      list-style: decimal;
      margin-left: 30px;
    }
    li {
      margin: 5px 0;
    }

    h1 {
      margin: 30px 0 20px !important;
      font-size: 28px;
      color: $clr-txt-title;
    }

    h2 {
      margin: 30px 0 20px;
      font-size: 22px;
      color: $clr-txt-title;
    }

    h3 {
      margin: 30px 0 20px;
      font-size: 18px;
      font-weight: $bold;
      color: $clr-txt-title;
    }

    h4 {
      margin: 30px 0 20px;
      font-size: 18px;
      color: $clr-txt-title;
    }

    img {
      max-width: 100%;
    }
  }
}

.versions {
  > li {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid $clr-ui-border;
    overflow: hidden;
    transition: $transition;
    cursor: pointer;

    &:hover {
      background-color: $clr-ui-bkg;

      h2 {
        color: $primary;
      }
    }

    &:last-child {
      border: 0;
    }

    h2 {
      @include font-size(21px);
      font-weight: $bold;
      color: $clr-txt-title;
      transition: $transition;

      label {
        display: inline-block;
        width: auto;
      }
    }

    p {
      float: left;
      margin: 35px 0 0;
      max-width: 70%;
    }

    .role-avatar {
      float: right;

      .avatar {
        @include basic-avatar;
      }

      .name {
        display: block;
        float: left;
        margin-top: 20px;
      }
    }
  }
}

.interactions {
  max-width: 540px;
  margin: 0 auto;
  border-left: 1px solid $primary;
  padding: 0 0 1px 30px;
  position: relative;

  &:after {
    content: ' ';
    display: block;
    width: 11px;
    height: 11px;
    background-color: $primary;
    position: absolute;
    bottom: 0;
    left: -6px;
  }

  li {
    position: relative;
    margin-bottom: 70px;
    transform: translatey(-10px);

    &:before {
      content: ' ';
      display: block;
      width: 15px;
      height: 15px;
      background-color: $primary;
      border-radius: 15px 15px 0 15px;
      transform: rotate(45deg);
      position: absolute;
      top: 10px;
      left: -38px;
    }

    &:last-child:after {
      display: none;
    }

    .title-wrapper {
      overflow: hidden;
      margin-bottom: 10px;

      .avatar {
        display: block;
        float: left;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-radius: 50%;
        overflow: hidden;

        img {
          display: block;
          width: 100%;
        }
      }

      p {
        float: left;
        color: $clr-txt-title;
        margin-top: 10px;
        font-weight: $bold;
      }
    }

    .message {
      padding: 5px 10px;
      background-color: $clr-ui-lightborder;
      border-radius: $border-radius;
      margin-bottom: 10px;
      position: relative;

      &:before {
        content: ' ';
        display: block;
        width: 0;
        height: 0;
        border-width: 5px;
        border-style: solid;
        border-color: $clr-ui-lightborder $clr-ui-lightborder transparent transparent;
        transform: rotate(-45deg);

        position: absolute;
        top: -5px;
        left: 15px;
      }
    }

    .date {
      font-weight: $bold;
      font-size: $sz-note;
      line-height: 18px;

      i {
        float: left;
        color: $primary;
        font-size: 18px;
        margin-right: 5px;
      }
    }
  }
}

.toolbar {
  width: 100%;
  margin-bottom: 40px;

  &:after {
    content: ' ';
    display: block;
    clear: both;
  }

  &.fs-only {
    .filter-wrapper {
      .coluna-filter {
        width: 100%;
      }
      select,
      input {
        width: 100%;
      }
    }

    .sort-wrapper {
      width: 32%;
      margin: 0;

      .coluna-sort {
        width: 90%;
      }

      .sorter {
        width: 10%;
      }
    }

    .btn {
      margin: 16px 0 0 2%;
    }
  }

  .btn {
    float: left;
    margin: 16px 2% 0 0;
    width: 18%;
    min-width: 0;
    height: 40px;
    padding: 0;
    text-align: center;
    line-height: 37px;
  }

  span {
    display: block;
    font-size: 12px;
  }

  input,
  select {
    display: block;
    float: left;
    padding: 0 10px;
    border: 1px solid $clr-ui-border;
    height: 40px;
    background-color: #fff;
    font: $regular $sz-content $Source;
    color: $clr-txt-content;
    font-size: 16px;
  }

  .filter-wrapper {
    float: left;
    width: 46%;
    margin-right: 2%;

    .coluna-filter {
      width: 30%;
      border-right: 0;
    }

    input {
      width: 70%;
      position: relative;
    }
  }

  .sort-wrapper {
    float: left;
    width: 20%;
    margin-right: 2%;

    .coluna-sort {
      width: 85%;
      border-right: 0;
    }

    .sorter {
      float: left;
      display: block;
      width: 15%;
      height: 40px;
      border: 1px solid $clr-ui-border;
      background: url(./assets/img/ico-desc.png) no-repeat center center;

      &.asc {
        background-image: url(./assets/img/ico-asc.png);
      }
    }
  }

  .view-wrapper {
    float: left;
    width: 10%;

    .inner {
      padding: 7px 10px 6px 10px;
      border: 1px solid $clr-ui-border;
      overflow: hidden;
    }

    a {
      display: block;
      float: left;
      width: 25px;
      height: 25px;

      &:first-child {
        margin-right: 10px;
      }

      &.active {
        span {
          border-color: #fff;
          background-color: $clr-ui-border;
        }
      }

      span {
        display: block;
        float: left;
        border: 1px solid $clr-ui-border;
        height: 10px;
        margin-bottom: 5px;
      }

      &.grid-selector {
        span {
          width: 10px;

          &:nth-child(odd) {
            margin-right: 5px;
          }
        }
      }

      &.list-selector {
        span {
          width: 25px;
          height: 5px;
          margin-bottom: 0;
        }
      }
    }
  }
}

.folder-list {
  overflow: hidden;

  li {
    float: left;
    width: 23.5%;
    margin: 0 2% 20px 0;
    padding-top: 15px;

    &:nth-child(4n) {
      margin-right: 0;
    }

    a {
      display: block;
      width: 100%;
      height: 160px;
      border: 1px solid $clr-ui-border;
      position: relative;
      transition: $transition;

      &:hover {
        opacity: $opacity;
      }

      &:after {
        display: block;
        content: ' ';
        width: 70px;
        height: 15px;
        background: url(./assets/img/folder-tip.png) no-repeat center center;
        position: absolute;
        top: -15px;
        left: -1px;
      }

      .folders-files {
        position: absolute;
        top: 10px;
        left: 10px;
        @include font-size(18px);
        line-height: 24px;

        span {
          display: block;
          height: 24px;
          margin-bottom: 10px;

          i {
            float: left;
            color: $primary;
            font-size: 21px;
            margin-right: 10px;
          }
        }
      }

      strong {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 8px 10px;
        background-color: $clr-ui-lightborder;
        border-top: 1px solid $clr-ui-border;
      }
    }
  }

  &.list-view {
    border-bottom: 1px solid $clr-ui-border;

    li {
      width: 100%;
      margin: 0;
      padding: 0;

      a {
        overflow: hidden;
        height: auto;
        border: 1px solid $clr-ui-border;
        border-bottom: 0;
        padding: 10px;

        &:after {
          display: none;
        }

        &:hover {
          background-color: $clr-ui-lightbkg;
          opacity: 1;

          strong {
            color: $primary;
          }
        }

        .folders-files {
          position: static;
          width: 60%;
          float: left;
          line-height: 17px;

          span {
            display: inline-block;
            width: 30%;
            height: 17px;
            margin: 0;
            font-size: $sz-note;

            i {
              font-size: 17px;
            }
          }
        }

        strong {
          position: static;
          float: left;
          width: 40%;
          padding: 0;
          background-color: transparent;
          border-top: 0;
          font-size: $sz-content;
          color: $clr-txt-title;
          transition: $transition;
        }
      }
    }
  }
}

.document-list {
  overflow: hidden;
  margin-top: 30px;

  &.trash {
    > li {
      &:hover {
        background-color: #fff;
        cursor: default;
        h2,
        h3 {
          color: inherit;
        }
      }
    }
  }

  &.list-view > li {
    float: none;
    width: 100%;
    padding: 10px;
    border: 1px solid $clr-ui-border;
    border-bottom: 0;
    margin: 0;
    overflow: hidden;
    transition: $transition;
    cursor: pointer;

    &:last-child {
      border-bottom: 1px solid $clr-ui-border;
    }

    .right-menu {
      display: none;
    }

    h2,
    h3 {
      float: left;
      @include font-size(16px);
      font-weight: $regular;
      min-height: 0;
      width: 40%;
      max-width: 100%;
      margin-bottom: 0;
    }

    p {
      float: left;
      margin-right: 0;
      width: 20%;
      @include font-size(14px);

      &.code {
        float: left;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: $bold;
      }

      &.attachments {
        margin-right: 0;
      }

      &.norm {
        display: none;
      }

      i {
        float: left;
        font-size: 21px;
        color: $primary;
        margin-right: 5px;
      }
    }

    .saved-in {
      display: none;
    }
  }

  & > li {
    float: left;
    width: 49%;
    padding: 20px;
    border: 1px solid $clr-ui-border;
    margin: 0 2% 2% 0;
    // overflow:hidden;
    transition: $transition;
    cursor: pointer;

    &:nth-child(even) {
      margin-right: 0;
    }

    &:hover {
      background-color: $clr-ui-lightbkg;

      h2,
      h3 {
        color: $primary;
      }
    }

    .right-menu {
      top: 10px;
      right: 15px;

      .submenu {
        i {
          display: inline-block;
          color: $primary;
          margin-right: 5px;
        }
      }
    }

    h2,
    h3 {
      @include font-size(20px);
      color: $clr-txt-title;
      font-weight: $bold;
      min-height: 55px;
      max-width: 85%;
      margin-bottom: 5px;
      transition: $transition;
    }

    p {
      float: left;
      height: 21px;
      line-height: 21px;
      margin-right: 60px;
      font-size: $sz-note;

      &.code {
        float: none;
        @include font-size(18px);
        color: $primary;
        margin-bottom: 10px;
      }

      &.attachments {
        margin-right: 50px;
      }

      &.norm {
        margin-right: 0;

        i {
          transform: translatey(3px);
          font-size: 17px;
        }
      }

      i {
        float: left;
        font-size: 21px;
        color: $primary;
        margin-right: 5px;
      }
    }

    .saved-in {
      font-size: $sz-note;
      color: $clr-txt-content;
      margin-bottom: 10px;

      strong {
        font-weight: $bold;
      }

      .breadcrumbs {
        float: none;
        display: inline-block;
        font-size: $sz-note;
        color: $clr-txt-content;
      }
    }
  }
}

.edition-list {
  li {
    padding-bottom: 30px;
    border-bottom: 1px solid $clr-ui-border;
    margin-bottom: 30px;

    h2 {
      @include font-size(21px);
      font-weight: $bold;
      color: $clr-txt-title;
      margin-bottom: 10px;
    }
  }
}

.controls-list {
  overflow: hidden;
  margin: 0 1%;
  width: 98%;

  li {
    padding: 20px 0;
    border-bottom: 1px solid $clr-ui-border;
    cursor: pointer;
    transition: $transition;

    &:hover {
      h3,
      p {
        color: $primary;
      }
    }

    &:last-child {
      border: 0;
    }

    > h3 {
      max-width: 60%;
      font-size: 19px;
      line-height: 1.4;
      margin-bottom: 10px;
      color: $clr-txt-title;
      transition: $transition;
    }

    p {
      transition: $transition;
    }

    .upper-details {
      position: absolute;
      top: 30px;
      right: 20px;
      min-width: 30%;

      p {
        float: left;
        line-height: 24px;
        margin-left: 30px;

        i {
          display: block;
          float: left;
          color: $primary;
          font-size: 24px;
          margin-right: 5px;
        }

        strong {
          font-weight: $bold;
        }
      }
    }

    .lower-details {
      width: 100%;

      font-size: $sz-note;

      em {
        color: $primary;
      }

      strong {
        font-weight: $bold;
      }
    }
  }
}

#highlights {
  width: 100%;
  margin-bottom: 30px;
  text-align: center;

  p {
    display: inline-block;
    font-weight: bold;
    max-width: 180px;
    margin: 0 20px 20px;
    vertical-align: top;

    strong {
      display: block;
      font-size: 30px;
      color: $primary;

      &.alert {
        color: $alert;
      }
    }
  }
}

.financial-list {
  overflow: hidden;
  margin: 0 1%;
  width: 98%;

  li {
    padding: 20px 0;
    border-bottom: 1px solid $clr-ui-border;
    transition: $transition;

    &:last-child {
      border: 0;
    }

    > h3 {
      max-width: 60%;
      font-size: 19px;
      line-height: 1.4;
      margin-bottom: 10px;
      color: $clr-txt-title;

      a {
        transition: $transition;

        &:hover {
          color: $primary;
        }
      }
    }

    .status {
      position: absolute;
      top: 25px;
      right: 0;
      font-size: $sz-note;
      color: $primary;

      &.alert {
        color: $alert;
      }
    }

    .lower-details {
      width: 100%;

      p {
        display: inline-block;
        margin-right: 30px;

        strong {
          font-weight: $bold;
        }
      }
    }
  }
}

.financial-detail {
  position: relative;

  h2 {
    font-size: 21px;
    font-weight: $bold;
    line-height: 1.4;
    margin-bottom: 10px;
    color: $clr-txt-title;
  }

  h3 {
    font-weight: $bold;
    margin-bottom: 10px;
  }

  .status {
    position: absolute;
    top: 5px;
    right: 0;
    font-size: $sz-note;
    color: $primary;

    &.alert {
      color: $alert;
    }
  }

  .lower-details {
    width: 100%;
    margin-bottom: 30px;

    p {
      display: inline-block;
      margin-right: 30px;

      strong {
        font-weight: $bold;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;

    tr {
      td {
        border: 1px solid $clr-ui-border;
        padding: 10px;

        strong {
          font-weight: $bold;

          &.alert {
            color: $alert;
          }
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .button-stack {
    .btn {
      margin: 0;
    }
  }
}

.readers-list {
  width: 100%;

  li {
    width: 100%;
    overflow: hidden;
    padding: 10px 0;
    border-bottom: 1px solid $clr-ui-border;

    &:last-child {
      border: 0;
    }

    &.pending {
      .user-info {
        opacity: 0.2;
      }
    }

    .user-info {
      float: left;
      overflow: hidden;

      .avatar {
        display: block;
        float: left;
        width: 40px;
        height: 40px;
        margin: 0 10px;
        border-radius: 50%;
        overflow: hidden;

        img {
          display: block;
          width: 100%;
        }
      }

      p {
        float: left;
        margin-top: 10px;
      }
    }

    .status {
      float: right;
      margin-top: 10px;
      font-size: $sz-note;
      color: $primary;

      &.alert {
        color: $alert;
      }
    }
  }
}

.form-reset {
  overflow: hidden;
}

.request-wrapper {
  margin-top: 40px;
  clear: both;

  h2 {
    font-size: 21px;
    color: $clr-txt-title;
    margin: 0 0 10px;
  }

  > p {
    margin-bottom: 30px;
  }

  ul {
    margin-bottom: 20px;
    overflow: hidden;

    li {
      label {
        display: block;
        padding: 20px;
        border: 1px solid $clr-ui-border;
        margin: 0;
        border-bottom: 0;
      }

      &:last-child label {
        border-bottom: 1px solid $clr-ui-border;
      }

      h3 {
        font-size: 19px;
        font-weight: $bold;
        margin-bottom: 10px;
      }
    }
  }
}

ul {
  li {
    position: relative;

    .buttons {
      position: absolute;
      top: 5px;
      right: 5px;
      opacity: 0;
      transition: $transition;

      a,
      button {
        display: block;
        float: left;
        width: 36px;
        height: 36px;
        margin-left: 10px;
        background-color: $primary;
        border-radius: 50%;
        border: 0;
        cursor: pointer;
        opacity: $opacity;
        transition: $transition;
        position: relative;

        &:hover {
          opacity: $opacity;
        }

        &.edit {
          &:before {
            font-family: 'icomoon';
            font-size: 17px;
            content: '\e90a';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
          }
        }

        &.remove {
          background-color: $alert;
          &:before {
            font-family: 'icomoon';
            font-size: 12px;
            content: '\e919';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
          }
        }

        &.resend {
          &:before {
            font-family: 'icomoon';
            font-size: 21px;
            content: '\e912';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
          }
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    &:hover .buttons {
      opacity: 1;
    }
  }
}

.data-transfer {
  float: left;
  width: 100%;
  overflow: hidden;

  label {
    float: left;
    width: 38%;

    select.multiple {
      min-height: 370px;
    }
  }

  .transfer-buttons {
    float: left;
    width: 20%;

    a {
      display: block;
      width: 40px;
      height: 40px;
      margin: 0 auto;

      &.btn-add {
        margin: 170px auto 20px;
        background: $primary url(./assets/img/arw-next.png) no-repeat center center;
      }

      &.btn-remove {
        background: $alert url(./assets/img/arw-prev.png) no-repeat center center;
      }
    }
  }
}

.notification,
.notification-list {
  max-width: 640px;
  margin: 0 auto;

  li {
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid $clr-ui-border;
    margin-bottom: 30px;
    overflow: hidden;

    &:last-child {
      border: 0;
      margin: 0;
    }
  }

  .notification-header {
    overflow: hidden;
    margin-bottom: 20px;

    .left {
      float: left;
      max-width: 70%;

      .avatar {
        display: block;
        float: left;
        width: 40px;
        height: 40px;
        margin: 0;
        border-radius: 50%;
        overflow: hidden;

        img {
          display: block;
          width: 100%;
        }
      }

      h2 {
        float: left;
        font-size: 18px;
        display: block;
        font-weight: $bold;
        color: $clr-txt-title;
      }
    }

    .date {
      float: right;
      max-width: 30%;
      color: $clr-txt-note;
      font-size: $sz-note;
    }
  }

  .answer {
    float: right;
    overflow: hidden;

    .avatar {
      display: block;
      float: right;
      width: 40px;
      height: 40px;
      margin: 0;
      border-radius: 50%;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
      }
    }

    h2 {
      float: right;
      margin-right: 10px;
      font-size: 14px;
      text-align: right;
      max-width: 400px;

      strong {
        font-size: 18px;
        display: block;
        font-weight: $bold;
        color: $clr-txt-title;
      }

      a {
        color: $primary;
      }
    }
  }

  .message {
    padding: 10px;
    background-color: $clr-ui-lightborder;
    border-radius: $border-radius;
    margin-bottom: 10px;
    width: 90%;
    font-size: $sz-note;
    position: relative;

    &:before {
      content: ' ';
      display: none;
      width: 0;
      height: 0;
      border-width: 5px;
      border-style: solid;
      border-color: $clr-ui-lightborder $clr-ui-lightborder transparent transparent;
      transform: rotate(-45deg);

      position: absolute;
      top: -5px;
      left: 15px;
    }

    &.response {
      width: 90%;
      margin: 0 0 20px 10%;
      background-color: $mintgreen;

      &:before {
        top: auto;
        left: auto;
        bottom: -5px;
        right: 15px;
        transform: rotate(45deg);
        border-color: transparent $mintgreen $mintgreen transparent;
      }
    }
  }
}

.use {
  .bar {
    width: 100%;
    height: 80px;
    background-color: $clr-ui-bkg;

    .used {
      height: 80px;
      background-color: $primary;
      position: relative;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-weight: $bold;
        font-size: 20px;

        &.lessthanten {
          left: 120%;
          width: 50px;
          transform: translate(0%, -50%);
          color: $clr-txt-content;
        }
      }
      &.morethanninety {
        background-color: $alert;
      }
    }
  }
}

/* Minha conta */
.account-header {
  overflow: hidden;
  margin-bottom: 30px;

  .user-tag {
    float: left;
    max-width: 80%;

    .avatar {
      float: left;
      width: 130px;
      height: 130px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 20px;

      img {
        display: block;
        width: 100%;
      }
    }

    .info {
      float: left;
      margin-top: 20px;

      h2 {
        @include font-size(24px);
        font-weight: $bold;
        color: $clr-txt-title;
      }

      .role {
        font-size: $sz-note;
        color: $primary;
        margin-bottom: 20px;
      }

      .module {
        font-size: $sz-note;

        span {
          font-weight: $bold;
        }
      }
    }
  }

  .user-stats {
    float: right;

    a {
      display: block;
      height: 26px;
      line-height: 26px;
      font-size: 18px;
      background: no-repeat right center;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      i {
        float: right;
        margin-left: 10px;
        font-size: 26px;
        color: $primary;
      }
    }

    .folders {
      padding-right: 34px;
      margin-bottom: 20px;
      background-image: url(./assets/img/ico-pasta-alt.png);
    }

    .documents {
      padding-right: 28px;
      background-image: url(./assets/img/ico-doc-alt.png);
    }
  }
}

.tabs {
  width: 100%;
  overflow: hidden;
  margin-bottom: 30px;
  border-bottom: 1px solid $primary;

  li {
    float: left;

    a {
      display: block;
      padding: 10px 25px;
      font-size: 18px;
      transition: $transition;

      &:hover,
      &.active {
        background-color: $primary;
        color: #fff;
      }
    }
  }
}

.pending-list {
  margin-bottom: 30px;

  li {
    width: 100%;
    padding: 20px;
    border: 1px solid $clr-ui-lightborder;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    transition: $transition;

    cursor: pointer;

    .tooltip {
      position: absolute;
      bottom: 16px;
      right: 10px;
      opacity: 0;
      transition: $transition;
      background-color: $alert;
      color: #fff;
      padding: 5px 10px;
      font-size: $sz-note;
    }

    &:hover {
      background-color: $clr-ui-lightbkg;
      border-color: $clr-ui-border;

      .tooltip {
        opacity: 1;
      }
    }

    h3 {
      font-size: 19px;
      margin-bottom: 15px;
      transition: $transition;

      strong {
        font-weight: $bold;
      }
    }

    &:hover h3 {
      color: $clr-txt-title;
    }

    .message {
      padding: 10px;
      background-color: $clr-ui-lightborder;
      border-radius: $border-radius;
      font-size: $sz-note;
      margin-bottom: 15px;
      width: 90%;

      &.response {
        width: 90%;
        margin-left: 10%;
        background-color: $mintgreen;
      }
    }

    .relevant-info {
      float: left;

      strong {
        font-weight: $bold;
      }
    }

    .status {
      float: right;
      font-size: $sz-note;
      color: $primary;
    }

    .delete {
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: $alert;
      color: #fff;
      text-align: center;
      font-size: 18px;
      line-height: 27px;
      opacity: 0;
      transition: $transition;

      position: absolute;
      top: 0;
      right: 0;
    }

    &:hover .delete {
      opacity: 1;
    }
  }
}

.request-info {
  overflow: hidden;
  margin-bottom: 40px;

  h2 {
    font-size: 21px;
    color: $clr-txt-title;
    margin-bottom: 10px;
  }

  .request {
    float: left;
    width: 70%;
  }

  .requester {
    float: left;
    width: 30%;

    .role-avatar {
      overflow: hidden;

      .name {
        float: left;
        max-width: 170px;
        margin-top: 20px;
      }

      .avatar {
        @include basic-avatar;
      }
    }
  }
}

.notification-reponse {
  width: 100%;
  border: 1px solid $clr-ui-lightborder;
  padding: 10px 20px 0;
  border-radius: 5px;

  &.success {
    border-color: $primary;

    p {
      strong {
        color: $primary;
      }
    }
  }

  &.error {
    border-color: $alert;

    p {
      strong {
        color: $alert;
      }
    }
  }

  p {
    margin-bottom: 10px;

    strong {
      display: block;
      font-weight: $bold;
      margin-bottom: 10px;
    }
  }
}

#container {
  .search-results {
    .document-list,
    .folder-list {
      margin-bottom: 50px;
    }

    h2 {
      color: $clr-txt-title;
      @include font-size(22px);
      margin: 0 0 30px;
    }
  }
}

#container {
  .empty-folder {
    width: 100%;
    margin: 50px 0 200px;
    text-align: center;
    line-height: 30px;
    @include font-size($sz-content);

    strong {
      display: block;
      color: $clr-txt-title;
      font-weight: $bold;
      @include font-size(20px);
    }
  }
}

#list-term {
  li {
    padding: 20px 0;
    border-bottom: 1px solid $clr-ui-border;

    transition: $transition;

    &:hover {
      h3,
      p {
        color: $primary;
      }
    }

    &:last-child {
      border: 0;
    }

    h3 {
      font-size: 19px;
      line-height: 1.4;
      margin-bottom: 10px;
      color: $clr-txt-title;
      transition: $transition;
    }
    p {
      transition: $transition;
    }
    a {
      display: block;
    }
  }
}
.inner-title {
  font-size: 24px;
  color: $clr-txt-title;
  margin-bottom: 20px;
}

.tree-view {
  border: 1px solid $clr-ui-border;
  padding: 15px;
  clear: both;
  position: relative;
  width: 98%;
  margin: 0 1% 30px;

  .open-folder {
    position: absolute;
    left: -20px;
    top: 2px;
    display: block;
    font-size: 12px;
    padding: 1px;
    transition: $transition;

    &.opened {
      transform: rotate(90deg);
    }
  }

  a {
    transition: $transition;

    &.selected {
      color: $primary;
    }
  }

  ul {
    padding-left: 30px;
    margin: 10px 0;
    display: none;

    &.root {
      display: block;
    }

    li {
      position: relative;
      margin-bottom: 5px;
    }
  }
}

#bubble-alerts {
  position: fixed;
  bottom: 60px;
  left: 2%;
  z-index: 2;
  width: 94%;
  max-width: 400px;

  li {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: $global-shadow;
    text-align: center;

    .close {
      display: block;
      position: absolute;
      top: -10px;
      right: -10px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: $alert;

      &:before {
        font-family: 'icomoon';
        font-size: 8px;
        content: '\e919';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
      }
    }

    p {
      line-height: 1.4;
      font-size: $sz-note;

      strong {
        display: block;
        font-weight: $bold;
        font-size: 18px;
        margin-bottom: 5px;
      }

      a {
        color: $alert;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.info {
      .close {
        background-color: $clr-support-blue;
      }

      p {
        a,
        span {
          font-weight: $bold;
          color: $clr-support-blue;
        }
      }
    }

    &.warning {
      .close {
        background-color: $clr-support-yellow;
      }

      p {
        a,
        span {
          font-weight: $bold;
          color: $clr-support-yellow;
        }
      }
    }
  }
}

/* Footer */
#footer {
  width: 100%;
  border-top: 1px solid $clr-ui-border;
  padding: 15px 7%;
  background-color: #fff;
  overflow: hidden;
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;

  .copy {
    float: left;
    font-size: $sz-note;
  }

  .uniware {
    float: right;

    img {
      display: block;
    }
  }
}

/* Select de usuários com filtro */
.user-selection {
  width: 98%;
  margin: 0 1% 20px;

  h2 {
    color: $clr-txt-title;
    margin-bottom: 10px;
  }

  #filter-wrapper {
    display: block;
    width: 100%;
    margin: 0;
    position: relative;

    &:before {
      font-family: 'icomoon';
      font-size: 19px;
      content: '\e918';
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translatey(-50%);
      color: $clr-txt-note;
    }

    input {
      display: block;
      width: 100%;
      color: $clr-txt-content;
      padding: 10px 10px 10px 40px;
      border: 2px solid $clr-ui-border;
      transition: all 200ms ease-in-out;
      border-radius: 0;
      margin: 0;

      &:focus {
        border: 2px solid $primary;
      }
    }
  }

  .list-select {
    width: 100%;
    border: 1px solid $clr-ui-border;
    border-top: 0;
    margin-bottom: 20px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;

    li {
      width: 100%;
      border-top: 1px solid $clr-ui-border;
      overflow: auto;

      -moz-user-select: none;
      -o-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &.contain {
        display: none;
      }

      &:first-child {
        border: 0;
      }

      label {
        display: block;
        overflow: hidden;
        padding: 8px 10px 5px;
        line-height: 50px;
        cursor: pointer;
        transition: all 200ms ease-in-out;
        width: 100%;
        margin: 0;

        .avatar {
          display: block;
          float: left;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 10px;
          overflow: hidden;
          background-color: #fff;

          img {
            display: block;
            width: 100%;
          }
        }

        .hidden {
          display: none;
        }

        &:hover {
          background-color: #ebf8f8;
        }

        &.selected {
          background-color: #d8f2f2;
        }
      }
    }
  }
}

/* Lista de usuários */
#list-user {
  overflow: hidden;

  li {
    float: left;
    width: 48%;
    padding: 15px;
    border: 1px solid $clr-ui-lightborder;
    margin: 0 1% 20px;
    border-radius: $border-radius;
    overflow: hidden;
    position: relative;

    .avatar {
      float: left;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 20px;

      img {
        display: block;
        width: 100%;
      }
    }

    .info {
      float: left;
      max-width: 300px;

      h2 {
        font-weight: $bold;
        margin-bottom: 5px;
      }

      p {
        color: $primary;
        font-size: $sz-note;
      }
    }
  }
}

/* Módulos */
.modules {
  &:after {
    content: ' ';
    display: block;
    clear: both;
  }

  .modules {
    float: left;
    width: 46%;
    padding: 60px 20px 20px;
    min-height: 580px;
    border: 1px solid $clr-ui-lightborder;
    margin: 0 2% 50px;
    text-align: center;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.05);

    h2 {
      font-size: 27px;
      color: $clr-txt-title;
      margin-bottom: 30px;

      span {
        font-weight: $bold;
      }
    }

    .buttons {
      position: absolute;
      top: 5px;
      right: 5px;
      opacity: 0;
      transition: $transition;

      a,
      button {
        display: block;
        float: left;
        width: 36px;
        height: 36px;
        margin-left: 10px;
        background-color: $primary;
        border-radius: 50%;
        border: 0;
        cursor: pointer;
        opacity: $opacity;
        transition: $transition;
        position: relative;

        &:hover {
          opacity: $opacity;
        }

        &.edit {
          &:before {
            font-family: 'icomoon';
            font-size: 17px;
            content: '\e90a';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
          }
        }

        &.remove {
          background-color: $alert;
          &:before {
            font-family: 'icomoon';
            font-size: 12px;
            content: '\e919';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
          }
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    &:hover .buttons {
      opacity: 1;
    }
  }

  .benefits {
    margin-bottom: 30px;

    li {
      line-height: 1.5;
      margin-bottom: 10px;
    }
  }

  .price {
    font-size: 66px;
    font-weight: $light;
    color: $clr-txt-title;
    margin-bottom: 40px;

    span {
      display: block;
      font-size: 18px;
      font-weight: $regular;
      color: $clr-txt-content;
    }
  }
}

// Pagamento
.selected-module {
  width: 100%;
  max-width: 400px;
  margin: 0 auto 80px;
  text-align: center;

  h2 {
    @include font-size(24px);
    font-weight: bold;
    margin-bottom: 10px;
    color: $clr-txt-title;
  }

  .benefits {
    margin-bottom: 30px;

    li {
      margin: 0 0 5px;
    }
  }

  p {
    font-size: 40px;
    font-weight: $light;

    span {
      display: block;
      font-size: $sz-content;
      font-weight: $regular;
    }
  }
}

.payment-methods {
  width: 100%;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 2%;
    max-width: 340px;
    padding: 0 30px;

    .ico {
      width: 60px;
      height: 60px;
      margin: 0 auto 20px;
      border-radius: 50%;
      background: $primary no-repeat center center;
      background-size: contain;

      &.barcode {
        background-image: url(./assets/img/ico-barcode.png);
      }

      &.creditcard {
        background-image: url(./assets/img/ico-creditcard.png);
      }
    }

    h3 {
      @include font-size(20px);
      font-weight: $bold;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
    }
  }
}

// Data Tables
.dataTables_wrapper {
  table.dataTable {
    border: 1px solid $clr-ui-border;
    border-collapse: collapse;
    margin-bottom: 20px;
    max-width: 100%;
    min-width: 0;

    thead {
      tr {
        th {
          border: 1px solid $clr-ui-border;
          background-color: $clr-ui-bkg;

          &.sorting_asc {
            background-image: url(./assets/img/sort_asc.png);
          }

          &.sorting_desc {
            background-image: url(./assets/img/sort_desc.png);
          }

          &.sorting {
            background-image: url(./assets/img/sort_none.png);
          }
        }
      }
    }

    tbody {
      tr {
        td {
          border: 1px solid $clr-ui-border;

          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  .dataTables_length {
    color: $clr-txt-content;

    select {
      display: inline-block;
      border: 1px solid $clr-ui-border;
      padding: 3px;

      font-family: $Source;
      font-size: $sz-content;
      color: $clr-txt-content;
    }
  }

  .dataTables_paginate {
    .paginate_button {
      padding: 3px 10px;
      border: 1px solid $clr-ui-border;
      color: $clr-txt-content;
      background: #fff;

      &.current {
        border-color: $primary;
        color: #fff !important;
        background: $primary;
        transition: $transition;

        &:hover {
          border-color: $primary;
          color: #fff !important;
          background: $primary;
          opacity: 0.6;
        }
      }

      &.disabled {
        border-color: $clr-ui-lightborder;
        background-color: $clr-ui-lightbkg;
        color: $clr-txt-content;
        cursor: default;

        &:hover {
          border-color: $clr-ui-lightborder;
          background-color: $clr-ui-lightbkg;
        }
      }
    }
  }

  .rounded-btn {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    transition: $transition;

    &:hover {
      opacity: 0.6;
    }

    &.edit {
      background-color: $primary;
    }
  }
}

.pointer {
  cursor: pointer;

  i:hover {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
}

.seal-img {
  height: 70px;
  width: 70px;
  border-radius: 35px;
}

/* */
#section-desc {
  display: inline-block;
  padding: 0 5px;
  min-width: 100%;

  > p {
    margin-bottom: 3px;
    color: #444;
    text-align: left;
  }
  .editor-view {
    border: 1px solid $clr-ui-border;
    border-bottom: 0;
    margin-bottom: 0;
    display: inline-block;
    width: 100%;
    height: 150px;
    overflow: auto;
  }

  .document-editor__toolbar.requisite-editor {
    margin-top: -5px;
  }

  #editor-view {
    .ck-content {
      min-height: 150px;
      border: 0;
    }
  }
}

/* Norma */
.norm {
  td {
    vertical-align: middle;
  }
}

/* Páginas de erro */
#container {
  #error-pages {
    h1 {
      font-size: 110px;
      font-weight: $light;
      line-height: 1;
      margin-bottom: 0;
    }

    h2 {
      font-size: $sz-title;
      color: $clr-txt-title;
      margin-bottom: 20px;
    }

    p {
      font-size: $sz-content;

      a {
        color: $primary;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

/*
=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
=-=-=- Lista de crons no admin -=-=-=
*/
#list-crons {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;

  li {
    flex: 0 1 auto;
    width: 48%;
    margin: 0 2% 20px 0;
    border: 1px solid $clr-ui-lightborder;
    padding: 20px;
    border-radius: $border-radius;

    &:nth-child(even) {
      margin-right: 0;
    }

    h3 {
      font-weight: $bold;
      color: $clr-txt-title;
      font-size: 22px;
      margin-bottom: 20px;
    }

    p {
      line-height: 1.5;
      margin-bottom: 20px;
    }

    .btn {
      margin: 0;
    }
  }
}

/*
=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
=-=-=-= Backup e Restauração -=-=-=
*/
#backup {
  display: flex;
  flex-wrap: wrap;

  .col {
    flex: 0 1 auto;
    width: 48%;
    margin: 0 4% 20px 0;
    border: 1px solid $clr-ui-lightborder;
    padding: 20px;
    border-radius: $border-radius;

    &:nth-child(even) {
      margin-right: 0;
    }

    h2 {
      font-weight: $bold;
      color: $clr-txt-title;
      font-size: 22px;
      margin-bottom: 20px;
    }

    ul {
      li {
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid $clr-ui-lightborder;
        overflow: hidden;

        &:last-child {
          border: 0;
        }

        h3 {
          color: $clr-txt-title;
          margin-bottom: 10px;
          float: left;
          width: 70%;
          line-height: 30px;

          strong {
            font-weight: $bold;
          }
        }

        a {
          display: block;
          float: right;
          width: 30%;
          font-weight: $bold;
          padding: 5px 20px;
          border: 1px solid $primary;
          border-radius: 5px;
          color: $primary;
          transition: all 300ms ease-in-out;

          &:hover {
            background-color: $primary;
            color: #fff;
          }
        }
      }
    }

    .attach-button {
      margin-bottom: 10px;
    }

    .btn {
      margin-bottom: 0;
    }
  }
}

#logo-doc {
  max-width: 100px;
  float: left;
}

/* Cadastro e Login */
@import 'register-login.scss';

/* Responsivo */
@media screen and (max-width: 1500px) {
  #smart-button {
    ul {
      background-color: #fff;
    }
  }
}

@media screen and (max-width: 1140px) {
  #dashboard-wrapper {
    .dashboard-block {
      .dashboard-list {
        li {
          h3 {
            float: none;
            max-width: 100%;
            width: 100%;
            margin-bottom: 20px;
          }
          .upper-details {
            position: static;
            width: 100%;
            overflow: hidden;
            margin-bottom: 20px;

            .avatar-wrapper {
              float: none;
            }
          }
          .lower-details {
            position: static;
            width: 100%;
          }
        }
      }
    }
  }

  .controls-list {
    li {
      h3 {
        float: none;
        max-width: 100%;
        width: 100%;
        margin-bottom: 20px;
      }
      .upper-details {
        position: static;
        width: 100%;
        overflow: hidden;
        margin-bottom: 20px;

        p {
          margin-left: 0;
        }
      }
      .lower-details {
        position: static;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  #list-user {
    li {
      width: 98%;
    }
  }
  .avatar-selection {
    .avatar-placeholder {
      float: none;
      margin: 0 auto 30px;
    }

    .button-stack {
      float: none;
      width: 100%;
      margin: 0;
      text-align: center;
    }
  }

  .request-info {
    .request {
      width: 100%;
      margin-bottom: 40px;
    }

    .requester {
      width: 100%;
    }
  }

  .toolbar {
    &.fs-only .filter-wrapper,
    .filter-wrapper {
      width: 100%;
      margin: 0 0 20px;
    }

    &.fs-only .sort-wrapper {
      width: 100%;
    }

    .sort-wrapper {
      float: left;
      width: 30%;
      margin-right: 2%;
    }

    .view-wrapper {
      float: left;
      width: auto;
    }
  }

  #backup {
    .col {
      width: 100%;
      margin: 0 0 20px;
      ul {
        li {
          h3 {
            width: 100%;
            line-height: 1.3;
          }

          a {
            clear: left;
            float: none;
            width: 100%;
            max-width: 200px;
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 860px) {
  #dashboard-wrapper {
    column-count: 1;

    .dashboard-block {
      width: 100%;
      margin: 0 0 50px 0;

      .dashboard-list {
        li {
          .upper-details {
            a {
              display: none;
            }
          }

          .user {
            text-align: center;

            .avatar {
              float: none;
              margin: 0 auto 10px;
            }

            .info {
              width: 100%;
            }
          }
        }
      }
    }
  }

  #header {
    #main-search {
      background-color: #fff;
      position: fixed;
      top: 70px;
      left: 0;
      width: 100%;
      margin: 0;
      padding: 10px 10px 0;

      label {
        width: 100%;
        margin: 0;

        input {
          margin: 0;
        }
      }
    }
  }

  #document {
    #active-header {
      .left {
        float: left;
        width: 60%;
      }

      .right {
        float: left;
        width: 40%;
      }
    }
  }

  .folder-list {
    overflow: hidden;

    li {
      width: 48%;

      &:nth-child(2n),
      &:nth-child(4n) {
        margin-right: 0;
      }
    }
  }

  .notification,
  .notification-list {
    li {
      .notification-header {
        .left {
          max-width: 100%;
          width: 100%;
          margin: 0 0 10px;

          .avatar {
            margin: 0;
          }
        }
        .date {
          max-width: 100%;
          width: 100%;
          text-align: right;
          margin: 0;
        }
      }
    }
  }

  #document {
    #active-header {
      .upper {
        .roles {
          .role-avatar {
            width: 100%;
            margin-bottom: 30px;
          }
        }
      }

      .lower {
        .left {
          width: 100%;
        }
        .right {
          width: 100%;
          padding-bottom: 10px;
        }
      }
    }
  }

  #lista-crons {
    li {
      flex: 0 1 auto;
      width: 100%;
      margin: 0 0 20px;
    }
  }
}

@media screen and (max-width: 640px) {
  #container {
    margin: 60px auto;

    .title-button {
      margin-bottom: 30px;

      h1 {
        float: none;
        max-width: 100%;
        margin: 0 0 20px;
      }

      .button-stack {
        float: none;
        width: 100%;
        max-width: 100%;
        text-align: left;
      }
    }
  }

  .attachments {
    li {
      width: 100%;
      margin: 0 0 10px;
    }
  }

  .form {
    label {
      &.split2 {
        width: 98%;
      }

      &.split3 {
        width: 48%;

        &.colspan {
          width: 98%;
        }
      }

      &.split4 {
        width: 48%;

        &.colspan {
          width: 98%;
        }
      }
    }
  }

  .modules {
    .module {
      width: 100%;
      min-height: 0;
      padding: 60px 20px;
      border: 1px solid $clr-ui-border;
      margin: 0 0 30px;
      padding: 20px;
    }
  }

  #inactive-header {
    .info {
      max-width: 100%;
      width: 100%;
      margin-bottom: 20px;
    }

    .role-avatar {
      float: left;
      width: 100%;

      .name {
        float: left;
      }

      .avatar {
        float: left;
      }
    }
  }

  .versions {
    li {
      h2 {
        margin-bottom: 20px;
      }

      p {
        float: none;
        margin: 0 0 20px;
      }
      .role-avatar {
        float: none;
      }
    }
  }

  .document-list {
    li {
      width: 100%;
      margin: 0 0 2%;
    }
  }

  .data-transfer {
    label {
      width: 98%;
      margin: 0 1% 40px;
    }

    .transfer-buttons {
      margin: 0 0 40px;
      text-align: center;
      width: 100%;

      a {
        &.btn-add,
        &.btn-remove {
          margin: 0 10px;
          display: inline-block;
          transform: rotate(90deg);
        }
      }
    }
  }

  .heading-comparison {
    p {
      float: none;
      margin: 0 0 10px;
    }

    .btn {
      float: none;
    }
  }

  #version-comparison {
    .versao {
      width: 100%;
      margin: 0 0 50px;
    }
  }

  .tools {
    padding-bottom: 20px;

    li {
      width: 50%;
      margin-bottom: 10px;
    }
  }

  #dashboard-wrapper {
    .dashboard-block {
      .status-list {
        li {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }

  .financial-list {
    li {
      padding: 20px 0 10px;

      > h3 {
        max-width: 100%;
      }

      .status {
        position: static;
        margin-bottom: 10px;
      }

      .lower-details {
        width: 100%;

        p {
          display: block;
          margin: 0 0 10px;
        }
      }
    }
  }

  .financial-detail {
    .status {
      position: static;
      margin-bottom: 20px;
      font-size: $sz-content;
    }

    .lower-details {
      p {
        display: block;
        margin: 0 0 10px;

        strong {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  #container {
    padding-bottom: 0;
  }
  #header #notifications-tip {
    display: none;
  }
  #bubble-alert {
    bottom: 20px;
  }
  #footer {
    position: static;
    text-align: center;

    .copy {
      float: none;
      margin-bottom: 10px;
    }

    .uniware {
      float: none;
      width: 100%;

      img {
        display: inline-block;
      }
    }
  }

  #bubble-alerts {
    bottom: 0;
    width: 90%;
    left: 5%;
  }

  #document {
    #active-header {
      .left {
        width: 100%;
        border: 0;
      }
      .dates {
        .date {
          padding-bottom: 20px;
          width: 100%;
          margin-bottom: 10px;
        }
      }
      .right {
        width: 100%;
        padding: 0;
      }
    }
  }

  .toolbar {
    .filter-wrapper {
      .coluna-filter {
        width: 100%;
        border-width: 1px 1px 0 1px;
      }
      input {
        width: 100%;
      }
    }

    .sort-wrapper {
      width: 100%;
      margin: 0 0 20px;
    }

    .view-wrapper {
      display: none;
    }

    .btn {
      margin: 0;
      width: 100%;
    }
  }
}

@media screen and (max-width: 420px) {
  #container {
    padding-top: 60px;
  }

  .btn {
    width: 100%;
    margin: 0 0 20px;
  }

  #header {
    height: 60px;

    #menu-button {
      &:before {
        top: 16px;
      }
      &:after {
        bottom: 15px;
      }
      span {
        top: 29px;
      }

      &.active:before {
        top: 29px;
      }
      &.active:after {
        bottom: 28px;
      }
      &.active span {
        opacity: 0;
      }
    }

    #menu-button {
      width: 60px;
      height: 60px;

      &:before,
      &:after,
      span {
        left: 10px;
      }
    }
    #logo {
      width: 160px;
      margin: 10px 0 0 20px;

      img {
        max-width: 100%;
      }
    }
    #user-tip {
      width: 60px;
      height: 60px;
      margin: 0;
      border-radius: 0;
    }
  }
  #main-menu {
    padding-top: 90px;
  }

  #user-menu {
    top: 60px;
    width: 320px;
  }

  .form {
    label {
      &.split3 {
        width: 98%;
      }
      &.split4 {
        width: 98%;
      }
    }
  }

  #list-user {
    li {
      .avatar {
        float: none;
        margin: 0 auto 10px;
      }

      .info {
        width: 100%;
        margin: 0;
        text-align: center;
      }
    }
  }

  #smart-button {
    bottom: 40px;
  }

  #header {
    #main-search {
      top: 60px;
    }
  }

  .folder-list {
    li {
      width: 100%;
      margin: 0 0 20px;
    }
  }

  .document-list {
    li {
      .anexos {
        width: 100%;
        margin: 0 0 20px;
      }
      .status-leitura {
        width: 100%;
      }
    }
  }

  .pending-list {
    margin-bottom: 30px;

    li {
      .relevant-info {
        overflow: hidden;
        margin-bottom: 10px;
      }

      .tooltip {
        position: static;
        opacity: 1;
        width: 100%;
        text-align: center;
        clear: both;
      }
    }
  }

  #dashboard-wrapper {
    .dashboard-block {
      .dashboard-list {
        li {
          .lower-details {
            margin-bottom: 5px;
          }
          .tooltip {
            position: static;
            width: 100%;
            opacity: 1;
          }
        }
      }
    }
  }

  .tools {
    li {
      width: 100%;
    }
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

.rdw-editor-wrapper {
  .rdw-editor-toolbar {
    border: 1px solid #ccc;
  }

  .demo-editor {
    min-height: 700px;
    max-height: 800px;
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 0;
  }
}

// Emulação de tabela
#table-emule {
  margin-top: 30px;
  @include font-size(15px);

  &.lastOption {
    .head-table {
      ul {
        li {
          width: 18%;
          max-width: 160px;
          text-align: center;

          &:nth-child(2n) {
            text-align: left;
          }

          &:nth-child(3n) {
            text-align: left;
          }

          &:first-child {
            width: 40% !important;
            max-width: unset;
            text-align: left !important;
          }
        }
      }
    }
    .body-table {
      ul {
        li {
          &.enter {
            > .optionsContainerTable {
              .optionsHover {
                opacity: 1;
              }
            }
          }

          &.open {
            > span .requisite-item-list-title {
              .icon-next {
                transform: rotate(90deg);
              }
            }
          }

          .subLevel {
            width: 100%;
            clear: both;
            display: none;
            margin-top: 20px;
            margin-bottom: -20px;
            position: relative;

            &.open {
              display: block;
            }
            &.close {
              display: none;
            }

            li {
              padding-left: 15px;
              border-bottom: 0;

              > span:first-child {
                // padding-left: 15px;
              }
            }
          }

          span {
            width: 22%;
            max-width: 22%;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 150px;

            .icon-next {
              @include font-size(13px);
              position: relative;
              top: 1px;
              cursor: pointer;
              display: inline-block;
              padding: 5px;
            }

            &:nth-child(2n) {
              text-align: left;
              width: 30%;
              max-width: unset;
            }

            &:nth-child(3n) {
              text-align: left;
              width: 33%;
            }

            &:first-child {
              width: 33% !important;
              max-width: unset;
              text-align: left !important;
            }

            &:last-child,
            &.option {
              width: 48%;
              max-width: unset;
              text-align: right;
            }
            &.optionsHover {
              position: absolute;
              right: 0;
              background: white;
              width: 180px;

              opacity: 0;
              overflow: visible;

              a {
                padding: 5px;
                margin-right: 5px;
                display: inline-block;
                @include font-size(15px);
                padding: 15px;
                border-radius: 30px;
                background: $primary;
                color: $clr-ui-lightborder;
                text-align: center;
                height: 28px;
                width: 28px;
                padding: 5px;
                text-align: center;
                font-weight: bold;
                line-height: 23px;
                position: relative;
                z-index: 10;

                &:hover {
                  &:after {
                    opacity: 1;
                  }
                }
                &:after {
                  content: attr(title);
                  background: #5b5b5b;
                  position: absolute;
                  bottom: -27px;
                  left: -20px;
                  right: 0;
                  margin: 0 auto;
                  text-align: center;
                  width: 65px;
                  font-weight: normal;
                  @include font-size(11px);
                  padding: 0 6px;
                  border-radius: 3px;
                  opacity: 0;
                }
              }
            }

            &.option {
              .right-menu {
                z-index: unset;

                .submenu {
                  z-index: 1;
                  position: relative;
                  li {
                    border: 0;
                    padding: 0;
                    a {
                      &:hover {
                        text-decoration: underline;
                      }
                      font-weight: normal;
                      @include font-size(16px);
                      width: 100%;
                      text-align: left;
                    }
                  }
                }
                .btn-menu {
                  width: 43px !important;
                  padding: 0;
                  position: absolute;
                  top: 27px;
                  right: -9px;
                  transform: scale(0.8);
                  height: 25px;
                  float: right;
                  padding-top: 10px;
                  margin-top: -10px;

                  &:before,
                  &:after {
                    background: $clr-txt-content;
                  }

                  span {
                    width: 6px !important;
                    margin: 0;
                    background-color: $clr-txt-content;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .head-table {
    ul {
      display: flex;
      flex-flow: row wrap;
      border-bottom: 1px solid #ccc;
      li {
        flex: 1 1 auto;
        text-align: center;
        max-width: 25%;
        padding: 10px 0;
        color: $clr-txt-title;

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }
  .body-table {
    * {
      outline: 0;
    }
    li {
      display: flex;
      flex-flow: row wrap;
      padding: 20px 0;
      border-bottom: 1px solid #ccc;
      cursor: pointer;

      .dragComp {
        color: $clr-ui-border;
        cursor: move;
        width: 20px;
        height: 20px;
        margin: 4px 0 0 0;
      }

      .optionsContainerTable {
        display: flex;
        width: 480px;
      }

      .status {
        position: relative;
        overflow: visible !important;
        top: -3px;

        &.open {
          #changeState {
            display: block;
          }
        }

        #changeState {
          position: absolute;
          display: none;
          border: 1px solid #afaba5;
          z-index: 99;
          background: #f8f8f8;
          margin-top: 4px;

          &.auditStatus {
            width: calc(100% + 60px);
          }

          a {
            padding-left: 25px;
            width: 100%;
            padding: 2px 30px 4px;
            position: relative;
            display: block;
            margin: 6px 0 -1px;
            border-bottom: 1px solid #afaba5;

            &:before {
              content: '';
              display: block;
              width: 17px;
              height: 17px;
              position: absolute;
              left: 7px;
              top: 3px;
              border-radius: 20px;
            }
          }
        }
      }

      > span {
        flex: 1 1 auto;
        max-width: 25%;
        text-align: center;
        font-weight: $bold;
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }
}

.sweet-alert.modal-edit {
  top: 13% !important;
  max-height: 70vh;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  left: 0 !important;
  right: 0;
  display: block;
  margin: 0 auto !important;
  border-radius: 10px !important;

  &.disabled-overflow {
    overflow: visible !important ;

    .centered {
      overflow: visible !important;
    }

    .head-modal {
      margin: -18px -17px 20px -17px !important;
      border-radius: 10px 10px 0 0;
    }
  }

  .left-align {
    text-align: left;
  }

  div.alert {
    width: 97%;
    margin-top: 10px;
  }

  .text-muted {
    margin-bottom: 0;
  }

  &.lg {
    min-width: 800px;
    max-height: 90vh;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }

  &.md {
    min-width: 600px;
    max-height: 90vh;
    overflow-x: hidden !important;
    overflow-y: auto !important;

    .limit-box {
      .bar {
        width: 75%;
      }
      a.btn {
        width: 23%;
        @include font-size(13px);
      }
    }
  }

  &.sm {
    min-width: 400px;
  }

  .back {
    float: none;
  }

  .centered {
    text-align: left;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 560px;

    h1 {
      display: none;
    }

    h2 {
      text-align: left;
      @include font-size(14px);
    }
  }

  .head-modal {
    background: $primary;
    margin: -18px -20px 20px -20px;
    text-align: left;
    padding: 20px;
    position: relative;
    color: $clr-ui-lightbkg;
    font-size: 1.25rem;

    &.danger {
      background: $alert;
    }

    .close {
      position: absolute;
      right: 10px;
      top: 20px;
      width: 20px;
      height: 20px;
      white-space: nowrap;
      text-indent: 2000px;
      cursor: pointer;

      &:after {
        content: '';
        display: block;
        width: 3px;
        height: 23px;
        background: $clr-ui-lightbkg;
        transform: rotate(40deg);
        position: absolute;
        top: 0;
      }

      &:before {
        content: '';
        display: block;
        width: 3px;
        height: 23px;
        background: $clr-ui-lightbkg;
        transform: rotate(-40deg);
        position: absolute;
        top: 0;
      }
    }
  }

  .button-stack {
    margin-top: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  #table-emule .body-table li .optionsContainerTable {
    width: 415px !important;
  }

  #table-emule.lastOption .body-table ul li span {
    min-width: 140px !important;
  }
}

@media screen and (max-width: 1600px) {
  #table-emule .body-table li .optionsContainerTable {
    width: 480px;
  }
}

@media screen and (max-width: 1500px) {
  #table-emule .body-table li .optionsContainerTable {
    width: 480px;
  }
}

// Módulos
@import 'modules.scss';

// Biblioteca adicional
@import 'sweetalert2';
@import 'fonticon';

.selectCustom {
  border-radius: 0;

  .react-select__control {
    min-height: 40px;
    margin-top: 5px;
    border-radius: 3px;
    box-shadow: 0 0 0 0px transparent;
    border-color: $clr-ui-lightborder;

    .react-select__value-container {
      padding-top: 3px;
    }

    &.react-select__control--is-focused {
      border-color: $primary;
    }

    input[type='text'] {
      height: 13px;
    }
  }
}

.requisite {
  display: flex;
  flex: 2 1;
  width: 50%;
  flex-wrap: wrap;

  span {
    cursor: pointer;
    background-color: #53b7e8;
    margin: 5px;
    color: white;
    border-radius: 1px;
    float: left;
    text-align: center;
    min-width: 110px;
    padding: 3px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.requisites {
  .buttonStack {
    margin-top: 30px;
  }
}

.box-check {
  margin-left: 20px;
  label {
    position: relative;
    padding-left: 25px;
    input[type='checkbox'] {
      display: none;

      &:checked + span {
        &:before {
          content: '\ea10';
          font-family: 'icomoon';
          @include font-size(9px);
          background: $primary;
          color: #fff;
          text-align: center;
          border-color: $primary;
          line-height: 1.7;
        }
      }
    }
    .fake-check {
      &:before {
        position: absolute;
        left: 0;
        top: 5px;
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        border: 1px solid #ccc;
        border-radius: 3px;
      }
    }
  }
}

.container-section {
  border: 1px solid $clr-ui-lightborder;
  display: block;
  clear: both;
  margin: 10px 7px;
  padding: 10px 0;
  max-height: 300px;
  border-radius: 4px;
  // overflow-y: auto;
  // overflow-x: hidden;
}

.requisite-section {
  padding: 10px;
  margin: 20px 10px;
  border: 1px solid;
  display: flex;
  flex-flow: column;
  border: 1px solid $clr-ui-lightborder;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-style: italic;
    opacity: 0.9;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-style: italic;
    opacity: 0.9;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    font-style: italic;
    opacity: 0.9;
  }

  .add-checklist {
    padding: 5px 10px;
    border: 1px solid $clr-txt-content;
    display: block;
    width: 160px;
    text-align: center;
    margin-left: 7px;
    border-radius: 5px;
    margin-top: 5px;
    float: left;
    @include font-size(14px);
    cursor: pointer;
  }

  .split1 {
    margin-bottom: 0 !important;
  }

  .cnome::-webkit-input-placeholder {
    font-weight: bold;
  }
  textarea {
    min-height: 45px;
    height: 45px;
    overflow: hidden;
  }

  textarea,
  input[type='text'] {
    border: 1px solid transparent;
    font-size: 15px !important;
    padding: 3px 10px 3px 3px;
  }

  .checklist-item {
    // border:1px solid red;
    margin-top: 10px;
    position: relative;

    .bt {
      border: 1px solid $alert-darker;
      background: transparent;
      margin-right: 5px;
      border-radius: 3px;
      font-weight: bold;
      padding: 3px 7px;
      min-width: 25px;
      font-weight: bold;
      @include font-size(15px);
      cursor: pointer;
      color: $alert-darker;

      &.add {
        border: 1px solid $primary-icon;
        color: $primary-icon;
        margin-right: 0;
      }
    }

    input[type='checkbox']:checked + span {
      &:before {
        content: '\ea10';
        font-family: 'icomoon';
        @include font-size(9px);
        background: $primary;
        color: #fff;
        text-align: center;
        border-color: $primary;
        line-height: 1.7;
      }
    }

    .checkboxEdit {
      width: 20px;
      display: inline-block;
      position: relative;
      top: 0;
      left: 0;

      .check {
        &:before {
          position: absolute;
          left: 0;
          top: 11px;
          content: '';
          display: block;
          width: 15px;
          height: 15px;
          border: 1px solid #ccc;
          border-radius: 3px;
        }
      }
    }

    input[type='checkbox'] {
      position: relative;
      display: none;
      :before {
        position: absolute;
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        background: red;
        left: 0;
      }
    }
    input[type='text'] {
      width: auto;
      display: inline-block;
      height: 30px;
      width: 85%;
      padding-left: 2px;
      margin-left: 0px;
      margin-right: 10px;
    }
  }

  .close {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $alert;
    cursor: pointer;

    position: absolute;
    top: -10px;
    right: -10px;

    &:before {
      font-family: 'icomoon';
      font-size: 9px;
      content: '\e919';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }
}

.requisite-section-add {
  text-align: center;
  cursor: pointer;
  a {
    padding: 5px 10px;
    border: 1px solid $clr-txt-content;
    display: block;
    width: 200px;
    text-align: center;
    margin-left: 7px;
    border-radius: 5px;
    margin-top: 5px;
    float: left;
    @include font-size(14px);
    cursor: pointer;
  }
}

.requisite-item-list-title {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  margin-right: 20px;

  div:first-child {
    flex-basis: 40px;
  }

  div:nth-child(2) {
    flex-basis: 20%;

    &.inc-width {
      flex-basis: 80%;
    }
  }

  div:nth-child(3) {
    flex-basis: 65%;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 2.4em; /* (Number of lines you want visible) * (line-height) */
    line-height: 1.2em;
    text-align: justify;
    padding-left: 10px;
  }

  .requisite-item-list-description {
    font-weight: 400;
  }
}

.split2.alt-checkbox {
  padding-top: 30px;
}

.center-loading-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.loading-cursor {
  &, & * {
    cursor: wait !important;
  }
}


@import 'editor.scss';
