.uppercase {
    text-transform: uppercase;
}

.calculate {
    background-color: #f1f1f1;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
}